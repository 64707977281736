import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentIndex: "nifty",
    indexCandleData: {},
};

const toolsSlice = createSlice({
    name: "indexExchange",
    initialState,
    reducers: {
        updateIndexCandleData: (state, action) => {
            const { eventName, data } = action.payload;
            state.indexCandleData[eventName] = data;
        },
        updateIndex: (state, action) => {
            state.currentIndex = action.payload
        }
    },
});

export const {
    updateIndexCandleData,
    updateIndex
} = toolsSlice.actions;
export default toolsSlice.reducer;  
