import React from "react";
import Button from "@mui/material/Button";
import BasketForm from "./BasketForm";
import { BasketIcon } from "../../PublicComponents/Icons";
import { BasketCard } from "./BasketCard";
import { useDispatch, useSelector } from "react-redux";
import {
	updateAllBaskets,
	updateAllBasketsLegs,
	updateAllCurrentBasketFormFileds,
	updateBasketStatus,
	updateCurrentBasket,
} from "../../store/Reducer/BasketReducer";
import { http } from "../../Services/api.services";
import { showToast } from "../../store/Actions/Action";
import AddIcon from "@mui/icons-material/Add";
import { BasketSkelatons } from "./BasketSkelatons";

const NoBasketImg = require("../../assets/NoBasketImg.png");

const basketForm = {
	name: "",
	stoploss: {
		target: "",
		stoploss: "",
	},
};

const BasketsList = ({ getOrders }) => {
	const dispatch = useDispatch();
	const { admin } = useSelector((state) => state.admin);
	const user = useSelector((state) => state.user);
	const {
		allBaskets,
		basketStatus,
		currentBasket,
		currentBasketFormData,
		currentSelectedOrder,
	} = useSelector((state) => state.Baskets);
	const id = admin ? admin?.id : user?.id;

	// EXECUTE CURRENT BASKET

	const executeBasket = async (basketId) => {
		try {
			const _payloadData = {
				basket_id: basketId,
				user_id: id,
			};
			const response = await http.post(`orders/basket/execute`, _payloadData);
			if (response?.status == 200) {
				const _snackbar = {
					message: "Basket Executed Successfully!",
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
			}
		} catch (e) {
			console.log(`ERROR WHILE EXECUTING BASKET ${e}`);
		}
	};

	// OPEN CREATE BASKET FORM
	const createNewBasket = async () => {
		dispatch(updateBasketStatus("creating"));
		dispatch(updateCurrentBasket(null));
		dispatch(updateAllBasketsLegs(null));
		dispatch(updateAllCurrentBasketFormFileds(basketForm));
	};

	// GET ALL BASKETS INNER ORDERS DATA
	const getBasketInnerOrders = async (basketId) => {
		dispatch(updateAllBasketsLegs(null));
		try {
			const response = await http.get(`orders/basket/${basketId}`);
			if (response?.status == 200) {
				dispatch(updateAllBasketsLegs(response?.data?.data));
			}
		} catch (e) {
			console.log(`ERROR WHILE FETCHING INNER BASKET ORDERS ${e}`);
		}
	};


	return (
		<div className="w-full relative flex-1 h-full flex flex-col">
			{/* FORM TO CREATE BASKET  */}

			{currentBasketFormData &&
				Object.keys(currentBasketFormData).length > 0 &&
				basketStatus == "creating" && (
					<div className="w-full p-4">
						<BasketCard
							getOrders={(basket_id) => {
								getBasketInnerOrders(basket_id);
								dispatch(updateBasketStatus("basket selected"));
								// dispatch(updateCurrentBasket(currentBasketFormData));
							}}
							basket={currentBasketFormData}
						/>
					</div>
				)}

			{allBaskets ? (
				allBaskets.length > 0 ? (
					<div
						className={`w-full flex  flex-col overflow-y-auto flex-1 items-start  p-4 px-0`}
					>
						{allBaskets?.map((basket, index) => (
							<BasketCard
								getOrders={(basket_id) => {
									getBasketInnerOrders(basket_id);
									dispatch(updateCurrentBasket(basket));
									dispatch(updateBasketStatus("basket selected"));
								}}
								key={index}
								basket={basket}
								executeCurrentBasket={(basketId) => executeBasket(basketId)}
							/>
						))}
					</div>
				) : (
					<div className="w-full  h-full flex items-center justify-center ">
						<div className="flex flex-col items-center justify-center gap-4">
							<img src={NoBasketImg} alt="NoBasketImg" />
							<p className="text-[#666666] text-sm lg:text-xs font-semibold text-center">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</p>
							<Button onClick={() => createNewBasket()} variant="contained">
								Create Basket
							</Button>
						</div>
					</div>
				)
			) : (
				<BasketSkelatons />
			)}

			{allBaskets?.length > 0 ? (
				<div className="w-full   bg-white  flex items-start justify-center">
					<Button
						onClick={() => createNewBasket()}
						variant="contained"
						startIcon={<AddIcon />} // Adding the plus icon here
					>
						New Basket
					</Button>
				</div>
			) : null}
		</div>
	);
};

export default BasketsList;
