import * as React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import { useMarketMst } from "../../store/mobx/MarketStoreMST";
import { styled } from "@mui/styles";
import { http } from "../../Services/api.services";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import Popper from "@mui/material/Popper";
import { DeleteBaskterOrderIcon } from "../../PublicComponents/Icons";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { BasketFormSkelatons, BasketSkelatons } from "./BasketSkelatons";
import { updateAllBaskets, updateAllBasketsLegs, updateAllCurrentBasketFormFileds, updateBasketStatus, updateCurrentBasket, updateCurrentBasketFormData, updateCurrentBasketTab, updateCurrentSelectedOrder, updateMargins, } from "../../store/Reducer/BasketReducer";
import { showToast } from "../../store/Actions/Action";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";
import RefreshIcon from "@mui/icons-material/Refresh";
import ConfirmDeleteBasket from "./ConfirmDeleteBasket";

const FieldLabelCell = ({ title, index }) => index === 0 ? (
	<h6 className="text-[#666666] text-sm lg:text-xs font-semibold my-2">
		{title}
	</h6>
) : null;

// BUTTONS STYLES
const BuyButton = styled(Button)(({ theme }) => ({
	color: "#00B386",
	backgroundColor: "#F1FBF6",
	border: "1px solid #00B386",
	"&:hover": {
		backgroundColor: "#F1FBF6",
	},
	minWidth: "24px",
	width: "30px",
	height: "30px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: 10,
	fontWeight: "bold",
	borderRadius: 5,
	padding: "8px 16px",
	textTransform: "capitalize",
	// boxShadow: 'none'
}));

const SellButton = styled(Button)(({ theme }) => ({
	color: "#EB5B3CF7",
	backgroundColor: "#FFF6F6",
	border: "1px solid #EB5B3CF7",
	"&:hover": {
		backgroundColor: "#FFF6F6",
	},
	minWidth: "24px",
	width: "30px",
	height: "30px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: 10,
	fontWeight: "bold",
	borderRadius: 5,
	padding: "8px 16px",
	textTransform: "capitalize",
	// boxShadow: 'none'
}));

const _indexes = {
	NIFTY: "nifty",
	BANKNIFTY: "banknifty",
	FINNIFTY: "finnifty",
	MIDCPNIFTY: "midcpnifty",
	SENSEX: "sensex",
	BANKEX: "bankex",
};

const _basketOrderForm = {
	index: "",
	strike: "",
	expiry: "",
	quantity: "",
	price: null,
	transaction_type: "BUY",
	instrument_type: "PE",
};

const _basketForm = {
	name: "",
	stoploss: {
		target: "",
		stoploss: "",
	},
};

const BasketForm = ({ setIsCreating }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const { allLegs, currentBasket, currentBasketFormData, allBaskets, margins, currentSelectedOrder, basketPositions, } = useSelector((state) => state.Baskets);
	const { admin } = useSelector((state) => state.admin);
	const containerRef = React.useRef(null);
	//   const market = useMarketContext();
	const market = useMarketContext();
	const [form, setForm] = React.useState({ ..._basketForm });
	const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);
	const id = admin ? admin?.id : user?.id;
	const [basketOrderForm, setBasketOrderForm] = React.useState(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const parnetDiv = React.useRef(null);
	const childDiv = React.useRef(null);
	const [height, setHeight] = React.useState(0);
	const [refresh, setRefresh] = React.useState(false);
	const [openDeleteBasket, setOpenDeleteBasket] = React.useState(false);
	const [dialogueMessage, setDialogueMessage] = React.useState("");
	const [isExpired, setIsExpired] = React.useState(false);
	const [initialDataSet, setInitialDataSet] = React.useState(false);

	// // console.log(currentBasketFormData)
	// UPDATE BASKET FORM DATA
	React.useEffect(() => {
		try {
			if (!initialDataSet) {
				if (currentBasket) {
					setForm({ ...currentBasketFormData });
					setInitialDataSet(true);
				}
			}
		} catch (e) {
			console.log(`ERROR WHILE UPDATING BASKET FORM DATA ${e}`);
		}
	}, [currentBasketFormData]);

	// CHECK BASKET IS AVAILABLE IN POSITIONS OR NOT
	React.useEffect(() => {
		try {
			if (basketPositions && basketPositions?.length > 0) {
				const isBasketAvailableInPosition = basketPositions.some((position) => position.id === currentBasket?.id);
				if (isBasketAvailableInPosition) {
					setDialogueMessage(
						"This basket is avalable in position do you still want to delete this basket!"
					);
				} else {
					setDialogueMessage("do you  want to delete this basket!");
				}
			} else {
				setDialogueMessage("do you  want to delete this basket!");
			}
		} catch (e) {
			console.log(`ERROR WHILE CHEKING BASKET IN POSITION ${e}`);
		}
	}, [basketPositions, currentBasket]);

	// ADD MORE FORM
	const onAddMoreFormFunction = () => {
		setBasketOrderForm([...basketOrderForm, { ..._basketOrderForm }]);
	};

	// REMOVE FORM BY INDEX
	const onRemoveFormFunction = (idx, orderId) => {
		try {
			const allForms = [...basketOrderForm];
			if (idx > -1 && idx < allForms.length) {
				if (currentBasket && orderId) {
					allForms[idx].deleted = true;
					setBasketOrderForm(allForms);
				} else {
					allForms.splice(idx, 1);
					setBasketOrderForm(allForms);
				}
			}
		} catch (e) {
			console.log(`ERROR WHILE REMOVING ORDER FROM BASKET ${e}`);
		}
	};

	// ON FORM CHANGE FUNCTION
	const onOrderFormCahnge = (field, index, value) => {
		try {
			// Create a copy of basketOrderForm array
			const updatedForm = [...basketOrderForm];
			// Update the specific form object at given index with new value for specified field
			updatedForm[index] = { ...updatedForm[index], [field]: value, };
			// Additional logic for setting 'qty' based on 'index' field
			if (field === "index") {
				updatedForm[index] = { ...updatedForm[index], quantity: market[value?.toLowerCase()]?.qty || 0 };
			}
			// Update state with the modified array
			setBasketOrderForm(updatedForm);
		} catch (e) {
			console.log(`ERROR WHILE CHANGING FORM DATA ${e}`);
		}
	};

	// CREATE NEW BASKET
	const createBasket = async () => {
		setIsLoading(true);
		try {
			// Filter out invalid objects
			const validBasketOrders = basketOrderForm.filter((order) => {
				return (
					order.index !== "" &&
					order.strike !== "" &&
					order.expiry !== "" &&
					order.quantity !== 0
				);
			});

			const _payloadData = { ...currentBasketFormData };
			if (admin) {
				_payloadData.admin_id = admin?.id;
			} else {
				_payloadData.user_id = user?.id;
			}

			returnBasketInstrument(validBasketOrders).then((res) => {
				console.log(res);
				market.updateBasketPositionsWS({
					updateBasketPositions: true,
					basketPositions: market.setSignelMarketBasketPosition(res),
				});
			});

			if (validBasketOrders?.length > 0)
				_payloadData.orders = [...validBasketOrders];
			else _payloadData.orders = [];
			const response = await http.post(`orders/basket`, _payloadData);
			setIsLoading(false);
			if (response?.status == 201) {
				dispatch(updateCurrentBasket(response?.data?.data));
				dispatch(updateAllBasketsLegs(response?.data?.data?.orders));
				dispatch(updateBasketStatus("created"));
				// dispatch(updateCurrentBasket(currentBasketFormData));
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				return response.data.data;
			} else {
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
				return null;
			}
		} catch (e) {
			console.log(`ERROR WHILE CREATING BASKET ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
			setIsLoading(false);
			return null;
		}
	};

	// RETURN BASKET INSTRUMENTS BEFORE CREATE A BASKET 
	const returnBasketInstrument = (basket) => {
		return new Promise((resolve, reject) => {
			try {
				const instruments = [];
				for (let index = 0; index < basket.length; index++) {
					const leg = basket[index];
					const curr = market[leg.index?.toLowerCase()][leg.expiry]["options"][leg.instrument_type];
					// const instrument = Object.keys(curr).find(key => curr[key]['strikePrice'] == leg.strike)
					const instrument = Object.keys(curr).find((key) => {
						return curr[key]["strike"] == leg.strike;
					});
					instruments.push(instrument?.replaceAll("'", ""));
				}
				resolve(instruments);
			} catch (e) {
				reject(e);
			}
		});
	};

	// UPDATE LEGS
	React.useEffect(() => {
		try {
			if (allLegs) {
				if (allLegs?.length > 0) {
					const filteredLegs = allLegs.map((leg) => {
						const newObj = { ...leg };
						delete newObj.createdat;
						delete newObj.updatedat;
						return newObj; // Return the modified object
					});
					setBasketOrderForm(filteredLegs);
				} else {
					setBasketOrderForm([_basketOrderForm]);
				}
			} else {
				// setBasketOrderForm([_basketOrderForm]);
				setBasketOrderForm(null);
			}
		} catch (e) {
			console.log(`ERROR WHILE UPDATING LEGS ${e}`);
		}
	}, [allLegs]);

	// UPDATE BASKET ORDER FORM
	React.useEffect(() => {
		try {
			if (currentBasket) {
				dispatch(updateCurrentBasketFormData({ field: "name", value: currentBasket?.name || "" }));
				const _dumySl = {
					stoploss: "",
					target: "",
				};
				let finalSL = _dumySl;
				if (typeof currentBasket?.stoploss === "string") {
					finalSL = JSON.parse(currentBasket.stoploss);
				}
				else if (currentBasket?.stoploss && typeof currentBasket.stoploss === "object") {
					finalSL = currentBasket.stoploss;
				}
				dispatch(updateCurrentBasketFormData({ field: "stoploss", value: finalSL || _dumySl }));
			}
			else {
				setBasketOrderForm([_basketOrderForm]);
			}
		} catch (e) {
			console.log(`ERROR WHILE UPDATING BASKET DATA ${e}`);
		}
	}, [currentBasket]);

	const updateBasket = async (basketId, executing) => {
		setIsLoading(true);
		try {
			const _payloadData = { ...currentBasketFormData };
			_payloadData.orders = [...basketOrderForm];
			const response = await http.put(`orders/basket/${basketId}/order`, _payloadData);
			const _basketData = { ...currentBasket }; // Shallow copy of currentBasket
			_basketData.name = currentBasketFormData?.name; // Update name property if currentBasketFormData.name exists
			dispatch(updateCurrentBasket(_basketData));
			setIsLoading(false);
			if (response?.status == 201) {
				getAllBaskets();
				dispatch(updateCurrentBasket(response?.data?.data));
				getBasketInnerOrders(currentBasket?.id);
				if (executing) {
					executeBasket(response?.data?.data)  //EXECUTE BASKET AFTER UPDATE BASKET 
				}
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				return response?.data?.data;
			}
		} catch (e) {
			setIsLoading(false);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
			console.log(`ERROR WHILE UPDATING BASKET!`);
			return null;
		}
	};

	// GET INNER ORDER (LEGS)
	const getBasketInnerOrders = async (basketId) => {
		try {
			const response = await http.get(`orders/basket/${basketId}`);
			if (response?.status == 200) {
				dispatch(updateAllBasketsLegs(response?.data?.data));
				setBasketOrderForm(response?.data?.data);
			}
		} catch (e) {
			console.log(`ERROR WHILE FETCHING INNER BASKET ORDERS ${e}`);
		}
	};

	// GET ALL BASKETS
	const getAllBaskets = async () => {
		try {
			const response = await http.get(`orders/${id}/basket`);
			if (response.status == 200) {
				dispatch(updateAllBaskets(response?.data?.data));
			}
		} catch (e) {
			console.log(`ERROR WHILE FETCHING BASKETS ${e}`);
		}
	};

	// MANAGE DIV HEIGHT
	React.useEffect(() => {
		let parentHeight = parnetDiv?.current?.offsetHeight;
		let childHeight = childDiv?.current?.offsetHeight;
		setHeight(parentHeight - childHeight - 20);
	}, [parnetDiv?.current, childDiv?.current]);

	const createExecuteBody = (legs, type) => {
		return new Promise((resolve, reject) => {
			try {
				const _xLegs = legs.map(leg => {
					if (leg.transaction_type == type) {
						return {
							"exchange": leg.exchange,
							"index": leg.symbol,
							"instrument_token": leg.instrument_token,
							"price": leg.price || 0,
							"order_type": parseFloat(leg.price) > 0 ? "LIMIT" : leg.order_type,
							"product": leg.product,
							"quantity": leg.quantity,
							"tradingsymbol": leg.tradingsymbol,
							"transaction_type": leg.transaction_type,
							"expiry": leg.expiry,
							"validity": "DAY",
						}
					}
				}).filter(l => l != undefined)
				resolve(_xLegs)
			}
			catch (e) {
				reject(e)
			}
		})
	}

	// EXECUTE BASKET FUNCTION - NEW FUNCTION WITH ALL REQUIRED ORDERS IN A SEQUENCE
	const executeBasket = async (basket) => {
		/**
		* GET THE BASKET
		* SEPARATE BUY AND SELL LEGS 
		* CREATE INDIVIDUAL LEG BODY SAME AS SINGLE ORDER
		* ADD BASKET ID AND SL TP DETAILS INTO BODY AND SEND TO THE API
		*/
		try {
			// console.log(basket, )
			setIsLoading(true)
			console.log(basket.orders, 'bord', basketOrderForm, 'ford');

			const buy = await createExecuteBody(basket.orders || basketOrderForm, 'BUY')
			const sell = await createExecuteBody(basket.orders || basketOrderForm, 'SELL')
			// const [buy, sell] = await Promise.all([createExecuteBody(basketOrderForm, 'BUY'), createExecuteBody(basketOrderForm, 'SELL')])
			const body = {
				buy,
				sell,
				basket_id: basket.id,
				stoploss: currentBasketFormData?.stoploss,
				user_id: admin?.id,
				type: 'entry'
			}
			console.log(buy, sell)
			const found = basketPositions.find(bp => (bp.id == body.basket_id && (bp.quantity !== 0 || bp.open_orders?.length)))
			if (found) {
				// POSITON ALREADY OPEN FOR THIS BASKER
				body.type = 'pentry'
				body.pid = found.pid
			}
			console.log(JSON.stringify(body))
			const response = await http.post(`orders/basket/executeorders`, body)
			setIsLoading(false)
			if (response?.status == 200) {
				dispatch(updateBasketStatus("notSelected"));
				setTimeout(() => {
					dispatch(updateCurrentBasketTab(0));
				}, 1000);
				dispatch(updateCurrentBasket(null));
				dispatch(updateAllCurrentBasketFormFileds({}));
				setBasketOrderForm([_basketOrderForm]);
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
			} else {
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
			}
			// CALL APIS FOR FURTHER EXECUTION FLOW
		}
		catch (e) {
			console.log(`ERROR WHILE EXECUTING BASKET ${e}`);
			setIsLoading(false);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
			setIsLoading(false);
		}
	}

	// EXECUTE SELECTED BASKET - TERMINATED
	const _executeBasket = async (basketId) => {
		// THIS FUCNTION IS USING EARLIER METHOD TO CALL API``
		setIsLoading(true);
		try {
			const _payloadData = {
				basket_id: basketId,
				user_id: id,
			};
			const response = await http.post(`orders/basket/execute`, _payloadData);
			setIsLoading(false);
			if (response?.status == 200) {
				dispatch(updateBasketStatus("notSelected"));
				setTimeout(() => {
					dispatch(updateCurrentBasketTab(0));
				}, 1000);
				dispatch(updateCurrentBasket(null));
				dispatch(updateAllCurrentBasketFormFileds({}));
				setBasketOrderForm([_basketOrderForm]);
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
			} else {
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "error",
				};
				dispatch(showToast(_snackbar));
			}
		} catch (e) {
			console.log(`ERROR WHILE EXECUTING BASKET ${e}`);
			setIsLoading(false);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
			setIsLoading(false);
		}
	};

	// FETCH MARGINS;
	const getMargins = async () => {
		setRefresh(true);
		try {
			const _allOrders = basketOrderForm ? [...basketOrderForm] : [];
			console.log(_allOrders);
			let orders = [];
			_allOrders?.forEach((order, index) => {
				if (
					order?.index &&
					order?.quantity &&
					order?.strike &&
					order?.instrument_type &&
					order?.transaction_type &&
					order?.expiry &&
					order?.deleted !== true
				) {
					let _orderBody;
					if (order?.id) {
						_orderBody = {
							exchange: order?.exchange,
							tradingsymbol: order?.tradingsymbol,
							transaction_type: order?.transaction_type,
							order_type: order?.price ? "LIMIT" : "MARKET",
							quantity: order?.quantity,
							price: order?.price ? order?.price : 0,
						};
					} else {
						const _instruments =
							market[order.index.toLowerCase()][
								allCurrentExpiry[order.index.toLowerCase()]
							]?.options[order.instrument_type] || [];

						// Convert to array
						const _filteredinstruments = Object.values(_instruments);

						const filteredStrike = _filteredinstruments?.filter(
							(instrument, idx) => {
								return instrument?.strike === order?.strike;
							}
						);
						_orderBody = {
							exchange: filteredStrike[0]?.exchange,
							tradingsymbol: filteredStrike[0]?.tradingsymbol,
							transaction_type: order?.transaction_type,
							order_type: order?.price ? "LIMIT" : "MARKET",
							quantity: order?.quantity,
							price: order?.price ? order?.price : 0,
						};
					}
					_orderBody.price = parseFloat(_orderBody.price)
					orders.push(_orderBody);
				}
			});
			let _body = { orders };
			console.log(_body);
			const response = await http.post(
				`orders/${id}/basket/order/margins-required`,
				_body
			);
			setRefresh(false);
			if (response?.status == 200) {
				dispatch(updateMargins(response?.data));
			}
		} catch (e) {
			setRefresh(false);
			console.log(`ERROR WHILE FETCHING MARGINS ${e}`);
		}
	};

	React.useEffect(() => {
		dispatch(updateMargins(null));
	}, [currentBasket]);

	// UPDATE BASKET ORDER FORM ON INSTANT ORDER SELECT
	React.useEffect(() => {
		try {
			if (currentSelectedOrder) {
				if (basketOrderForm?.length > 1) {
					setBasketOrderForm((prevData) => [...prevData, currentSelectedOrder]);
					dispatch(updateCurrentSelectedOrder(null));
				} else {
					if (basketOrderForm) {
						const singleForm = basketOrderForm[0];
						const { index, strike, expiry, quantity, price } = singleForm;
						const hasValue =
							index !== "" ||
							strike !== "" ||
							expiry !== "" ||
							quantity !== "" ||
							price !== null;

						if (hasValue) {
							setBasketOrderForm((prevData) => [
								...prevData,
								currentSelectedOrder,
							]);
						} else {
							setBasketOrderForm([currentSelectedOrder]);
						}
						dispatch(updateCurrentSelectedOrder(null));
					}
				}
			}
		} catch (e) {
			console.log(`ERROR WHILE UPDATING ORDERS FROM INSTANT ORDER SELECT ${e}`);
		}
	}, [currentSelectedOrder, basketOrderForm]);

	// CHECK EXPIRED ORDERS
	React.useEffect(() => {
		console.log('run');
		try {
			const today = new Date();
			today.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

			if (basketOrderForm) {
				let isEx = false;
				basketOrderForm.forEach((order) => {
					const orderExpiry = new Date(order?.expiry);
					orderExpiry.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison
					if (order?.expiry) {
						if (orderExpiry >= today || order?.deleted) {
							return { ...order, expired: false };
						} else {
							console.log(
								`Order with expiry ${order.expiry} is less than today.`
							);
							isEx = true;
							return { ...order, expired: true };
						}
					} else {
						isEx = false;
					}
				});
				setIsExpired(isEx);
			}
		} catch (e) {
			console.log(`ERRO WHILE FETCHING EXPIRED ORDERS ${e}`);
		}
	}, [basketOrderForm]);

	// Function to extract specific keys from an object
	const extractRelevantKeys = (obj) => {
		try {
			const { strike, expiry, transaction_type, quantity, price, instrument_type, index, deleted } = obj;
			return { strike, expiry, transaction_type, quantity, price, instrument_type, index, deleted, };
		} catch (e) {
			console.log(e);
		}
	};

	// Function to compare allLegs and basketOrderForm based on specific keys
	const hasRelevantChanges = () => {
		try {
			const extractedAllLegs = allLegs.map(extractRelevantKeys);
			const extractedBasketOrderForm = basketOrderForm.map(extractRelevantKeys);
			return (JSON.stringify(extractedAllLegs) !== JSON.stringify(extractedBasketOrderForm));
		} catch (e) {
			console.log(`ERROR WHILE CHECKING CHANGES ${e}`);
		}
	};

	// Handler for the button click

	let startTime
	const handleClick = async () => {
		try {
			// startTime = new Date().getTime()
			if (currentBasket) {
				if (hasRelevantChanges()) {
					await updateBasket(currentBasket.id, 'executing');
					return
				} else {
					if (JSON.stringify(form) !== JSON.stringify(currentBasketFormData)) {
						await updateBasket(currentBasket.id, 'executing');
						return
					}
				}
				executeBasket(currentBasket)
				// await _executeBasket(currentBasket.id);
			} else {
				const newBasket = await createBasket();
				console.log(newBasket)
				if (newBasket) {
					// Only execute the basket if a new basket was successfully created
					// await _executeBasket(newBasket.id);
					executeBasket(newBasket)
				}
			}
		} catch (e) {
			console.log(e);
		}
	};

	// DELETE BASKET
	const deleteBasket = async (basketId) => {
		setIsLoading(true);
		try {
			const response = await http.delete(`orders/basket/${basketId}`);
			setIsLoading(false);
			if (response.status == 200) {
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				setOpenDeleteBasket(false);
				dispatch(updateCurrentBasket(null));
				getAllBaskets();
			}
		} catch (e) {
			console.log(`ERROR WHILE DELETING BASKET ${e}`);
			setIsLoading(false);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};


	// STYLES TO MANAGE FORM TEXTFILEDS AND AUTOCOMPLETES ON VARIOUS SCREENS
	const CustomPopper = styled(Popper)(({ theme }) => ({
		"& .MuiAutocomplete-option": {
			// fontSize: "12px",
			[theme.breakpoints.down("sm")]: {
				fontSize: "12px", // Small devices
			},
			[theme.breakpoints.up("md")]: {
				fontSize: "12px", // Medium devices and up
			},
			[theme.breakpoints.up("lg")]: {
				fontSize: "10px", // Large devices and up
			},
			[theme.breakpoints.up("xl")]: {
				fontSize: "12px", // Extra large devices and up
			},
		},
	}));

	const autoComStyle = {
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		"& .MuiAutocomplete-inputRoot": {
			fontSize: {
				xs: "12px",
				sm: "12px",
				lg: "10px",
				xl: "12px",
			},
		},
		maxWidth: {
			xs: "60px", // Small devices
			sm: "60px", // Small devices
			md: "60px", // Medium devices
			lg: "60px", // Large devices
			xl: "100px", // Extra large devices
		},
		minWidth: {
			xs: "60px", // Small devices
			sm: "60px", // Small devices
			md: "60px", // Medium devices
			lg: "60px", // Large devices
			xl: "100px", // Extra large devices
		},
	};

	const textfieldStyle = {
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		"& .MuiInputBase-root": {
			fontSize: {
				xs: "10px",
				sm: "10px",
				md: "11px",
				lg: "10px",
				xl: "12px",
			},
			maxWidth: {
				xs: "40px", // Small devices
				sm: "40px", // Small devices
				md: "40px", // Medium devices
				lg: "40px", // Large devices
				xl: "60px", // Extra large devices
			},
			minWidth: {
				xs: "40px", // Small devices
				sm: "40px", // Small devices
				md: "40px", // Medium devices
				lg: "40px", // Large devices
				xl: "60px", // Extra large devices
			},
		},
	};

	const autoTextfieldStyle = {
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		"& .MuiInputBase-root": {
			fontSize: {
				xs: "10px",
				sm: "10px",
				md: "10px",
				lg: "10px",
				xl: "12px",
			},
			maxWidth: {
				xs: "60px", // Small devices
				sm: "60px", // Small devices
				md: "60px", // Medium devices
				lg: "60px", // Large devices
				xl: "100px", // Extra large devices
			},
			minWidth: {
				xs: "60px", // Small devices
				sm: "60px", // Small devices
				md: "60px", // Medium devices
				lg: "60px", // Large devices
				xl: "100px", // Extra large devices
			},
		},
	};

	// STYLES TO MANAGE FORM TEXTFILEDS AND AUTOCOMPLETES ON VARIOUS SCREENS"

	return (
		<>
			<div className="w-full relative h-full" ref={parnetDiv}>
				<div style={{ height: height }} className="w-full overflow-y-auto px-2 lg:px-2 xl:px-4 py-2 flex flex-col gap-4">
					<FormControl fullWidth size="small">
						<TextField
							autoFocus
							className="font-bold"
							label={currentBasketFormData?.name ? null : "Enter Basket Name"}
							name={"name"}
							InputProps={{
								style: {
									fontWeight: "semi-bold",
									fontSize: "16px",
								},
							}}
							variant="outlined"
							type="text"
							size="small"
							// value={form?.name}
							sx={{
								backgroundColor: "#FFFFFF",
								borderRadius: "5px",
							}}
							value={currentBasketFormData?.name || ""}
							onChange={(e) => {
								dispatch(
									updateCurrentBasketFormData({
										field: "name",
										value: e.target.value,
									})
								);
							}}
						/>
					</FormControl>
					<div className="w-full">
						{basketOrderForm ? (
							basketOrderForm?.length > 0 ? (
								basketOrderForm?.map((form, index) => {
									return !form?.deleted ? (
										<div key={index} className="flex  items-center flex-wrap justify-between py-2 ">
											{/* <div className="min-w-[120px] lg:min-w-[80px] xl:min-w-[120px] max-w-[120px] lg:max-w-[80px] xl:max-w-[120px]"> */}
											<div className="">
												<FieldLabelCell index={index} title=" Index" />
												<FormControl>
													<Autocomplete
														fullWidth
														disableClearable
														size="small"
														id="choose-index"
														sx={autoComStyle}
														name={"index"}
														value={basketOrderForm[index].index}
														onChange={(e, value) => {
															onOrderFormCahnge("index", index, value);
														}}
														options={Object.keys(_indexes)}
														getOptionLabel={(option) => option || ""}
														renderInput={(params) => (
															<TextField placeholder="Index" {...params} />
														)}
														PopperComponent={(props) => (
															<CustomPopper {...props} />
														)}
													/>
												</FormControl>
											</div>
											<div className="">
												<FieldLabelCell index={index} title="Expiry" />
												<FormControl>
													{basketOrderForm[index].index ? (
														<Autocomplete
															fullWidth
															size="small"
															// sx={inputStyle}
															// disablePortal
															disableClearable
															id="choose-expiry"
															name={"expiry"}
															sx={autoComStyle}
															className={
																basketOrderForm[index]?.expired
																	? "autocomplete-error"
																	: "autocomplete-normal"
															}
															value={basketOrderForm[index].expiry}
															onChange={(e, value) =>
																onOrderFormCahnge("expiry", index, value)
															}
															options={
																market[
																	basketOrderForm[index].index.toLowerCase()
																]?.expiry?.slice(0, 5) || []
															}
															getOptionLabel={(option) => option || ""}
															renderInput={(params) => (
																<TextField
																	placeholder="Select or type expiry"
																	type="text"
																	{...params}
																// label="Expiry"
																/>
															)}
															PopperComponent={(props) => (
																<CustomPopper {...props} />
															)}
														/>
													) : (
														<TextField
															className="font-bold"
															// label={form?.expiry ? null : "Expiry"}
															name={"expiry"}
															sx={autoTextfieldStyle}
															variant="outlined"
															//
															type="text"
															size="small"
															value={basketOrderForm[index].expiry}
															onChange={(e) => onOrderFormCahnge(e)}
														/>
													)}
												</FormControl>
											</div>
											<div className="">
												<FieldLabelCell index={index} title="Strike" />
												<FormControl size="small">
													{market[basketOrderForm[index].index.toLowerCase()] ? (
														<Autocomplete
															fullWidth
															size="small"
															disablePortal
															disableClearable
															id="choose-strike-auto"
															value={basketOrderForm[index].strike}
															onChange={(e, value) =>
																onOrderFormCahnge("strike", index, value)
															}
															sx={autoComStyle}
															options={[
																...Object.entries(
																	market[
																		basketOrderForm[index].index.toLowerCase()
																	][
																		allCurrentExpiry[
																		basketOrderForm[index].index.toLowerCase()
																		]
																	]?.options?.CE
																),
																// ...Object.entries(
																// 	market[
																// 		basketOrderForm[index].index.toLowerCase()
																// 	][
																// 		allCurrentExpiry[
																// 		basketOrderForm[index].index.toLowerCase()
																// 		]
																// 	]?.options?.PE
																// ),
															].map((el) => el[1]["strike"])}
															getOptionLabel={(option) =>
																option?.toString() || ""
															}
															isOptionEqualToValue={(option, value) => true}
															renderInput={(params) => (
																<TextField
																	placeholder="Strikes"
																	{...params}
																// label="Strikes"
																/>
															)}
															PopperComponent={(props) => (
																<CustomPopper {...props} />
															)}
														/>
													) : (
														<TextField
															className="font-bold"
															// label={form?.srike ? null : "Strike"}
															name={"strike"}
															variant="outlined"
															size="small"
															type="text"
															sx={autoTextfieldStyle}
															value={basketOrderForm[index].strike}
															onChange={(e) => {
																onOrderFormCahnge(e);
															}}
														/>
													)}
												</FormControl>
											</div>
											<div className="">
												<FieldLabelCell index={index} title="Qty" />
												<FormControl size="small">
													<TextField
														className="font-bold"
														//   label={"Qty"}
														name={"quantity"}
														variant="outlined"
														size="small"
														sx={textfieldStyle}
														value={basketOrderForm[index].quantity}
														type="number"
														onChange={(e) =>
															onOrderFormCahnge(
																"quantity",
																index,
																parseInt(e.target.value)
															)
														}
													/>
												</FormControl>
											</div>
											<div className="">
												<FieldLabelCell index={index} title="lmt" />
												<FormControl size="small">
													<TextField
														className="font-bold"
														//   label={"Amount"}
														name={"price"}
														variant="outlined"
														size="small"
														//
														type="number"
														sx={textfieldStyle}
														value={basketOrderForm[index].price}
														onChange={(e) =>
															onOrderFormCahnge("price", index, e.target.value)
														}
													/>
												</FormControl>
											</div>
											<div>
												<FieldLabelCell index={index} title="B/S" />
												{basketOrderForm[index]?.transaction_type == "BUY" ? (
													<BuyButton
														onClick={() => {
															onOrderFormCahnge(
																"transaction_type",
																index,
																"SELL"
															);
														}}
														size="small"
														variant="contained">
														B
													</BuyButton>
												) : (
													<SellButton
														onClick={() => {
															onOrderFormCahnge(
																"transaction_type",
																index,
																"BUY"
															);
														}}
														size="small"
														variant="contained">
														S
													</SellButton>
												)}
											</div>
											<div>
												<FieldLabelCell index={index} title="PE/CE" />
												{basketOrderForm[index]?.instrument_type == "PE" ? (
													<SellButton
														onClick={() => {
															onOrderFormCahnge("instrument_type", index, "CE");
														}}
														size="small"
														variant="contained">
														PE
													</SellButton>
												) : (
													<BuyButton
														onClick={() => {
															onOrderFormCahnge("instrument_type", index, "PE");
														}}
														size="small"
														variant="contained">
														CE
													</BuyButton>
												)}
											</div>
											<IconButton
												onClick={() => {
													onRemoveFormFunction(index, form?.id);
												}}
												sx={{
													padding: 0,
													marginTop: index === 0 ? "25px" : "0px", // Conditional marginTop
												}}
												aria-label="delete">
												<CloseIcon
													sx={{ fontSize: "17px", color: "#666666" }}
												/>
											</IconButton>
										</div>
									) : null;
								})
							) : (
								<>NO ORDERS FOUND</>
							)
						) : (
							<>
								<BasketFormSkelatons />
							</>
						)}
					</div>

					{basketOrderForm && (
						<div className="py-2 w-full">
							<Button
								size="small"
								variant="contained"
								onClick={() => onAddMoreFormFunction()}
								startIcon={<AddIcon />}>
								Add
							</Button>
						</div>
					)}
				</div>

				<div ref={childDiv} className="flex absolute w-full bottom-0 left-0 px-4 border-t border-t-[#00000026] bg-[#FFFFFF] rounded-t-xl shadow-lg flex-col items-end justify-end gap-5 py-6">
					{isExpired ? (
						<h6 className="text-sm text-red-600">
							There are some expired orders in your basket. Please fix them.
						</h6>
					) : null}
					<div className="flex  items-center gap-3">
						<h6 className="text-sm text-[#989898]">
							➡️ Margin reqd :{" "}
							<span className="font-bold">
								{margins?.required_margin
									? margins?.required_margin?.toFixed(2)
									: "- -"}
							</span>
						</h6>
						<h6 className="text-sm text-[#989898]">
							💰 Avail. Fund :{" "}
							<span className="font-bold">
								{margins?.available_margin
									? margins?.available_margin?.toFixed(2)
									: "- -"}
							</span>
						</h6>
						<Tooltip title="Reload Margin">
							<IconButton
								className={`${refresh ? "animate-spin" : ""}`}
								onClick={() => {
									getMargins();
								}}
								color="buy"
								aria-label="delete"
							>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</div>
					<div className="w-full flex gap-3">
						<div className="w-1/2">
							<h6 className="text-[#666666] text-sm lg:text-xs font-semibold my-2">
								Stop Loss
							</h6>
							<TextField
								fullWidth
								placeholder="Stop Loss"
								size="small"
								variant="outlined"
								sx={{
									backgroundColor: "#FCFCFC",
									border: "1px solid #D9D9D9",
									borderRadius: "5px",
									"& .MuiInputBase-input": {
										textAlign: "center",
									},
								}}
								onChange={(e) => {
									const value = e.target.value;
									if (!isNaN(value)) {
										dispatch(
											updateCurrentBasketFormData({
												field: "stoploss.stoploss",
												value: Number(value), // Ensure the value is stored as a number
											})
										);
									}
								}}
								value={currentBasketFormData?.stoploss?.stoploss || ''} // Show empty if undefined
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<IconButton
												onClick={(e) => {
													e.stopPropagation();

													// Create a copy of the form object
													const _form = { ...currentBasketFormData };

													// Check if stoploss.stoploss is valid, then decrement
													const updatedValue =
														!isNaN(Number(_form?.stoploss?.stoploss)) && _form.stoploss.stoploss > 0
															? _form.stoploss.stoploss - 1
															: 0; // If invalid or less than 0, set to 0

													const _updateData = {
														..._form,
														stoploss: {
															..._form?.stoploss,
															stoploss: updatedValue,
														},
													};

													// Update the form fields with the updated form state
													dispatch(updateAllCurrentBasketFormFileds(_updateData));
												}}
											>
												<RemoveIcon />
											</IconButton>
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={(e) => {
													e.stopPropagation();

													// Create a copy of the form object
													const _form = { ...currentBasketFormData };

													// Increment the stoploss field value, defaulting to 1 if undefined or invalid
													const updatedValue = !isNaN(Number(_form?.stoploss?.stoploss))
														? Number(_form.stoploss.stoploss) + 1 // Convert to number before incrementing
														: 1; // If undefined or invalid, start from 1

													const _updateData = {
														..._form,
														stoploss: {
															..._form.stoploss,
															stoploss: updatedValue,
														},
													};

													// Update the form fields with the updated form state
													dispatch(updateAllCurrentBasketFormFileds(_updateData));
												}}
											>
												<AddIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</div>
						<div className="w-1/2">
							<h6 className="text-[#666666] text-sm lg:text-xs font-semibold my-2">
								Target Price
							</h6>
							<TextField
								fullWidth
								placeholder="Target Price"
								size="small"
								variant="outlined"
								sx={{
									backgroundColor: "#FCFCFC",
									border: "1px solid #D9D9D9",
									borderRadius: "5px",
									"& .MuiInputBase-input": {
										textAlign: "center",
									},
								}}
								onChange={(e) => {
									if (!isNaN(e.target.value)) {
										dispatch(
											updateCurrentBasketFormData({
												field: "stoploss.target",
												value: e.target.value,
											})
										);
									}
								}}
								value={
									currentBasketFormData?.stoploss?.target !== undefined
										? currentBasketFormData?.stoploss?.target
										: ""
								}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<IconButton
												onClick={(e) => {
													e.stopPropagation();

													// Create a copy of the form object
													const _form = { ...currentBasketFormData };

													// Check if stoploss and target exist and target is a valid number greater than 0
													if (!isNaN(Number(_form?.stoploss?.target)) && _form.stoploss.target > 0) {
														// Update the target field in the form object by decrementing it
														const _updateData = {
															..._form,
															stoploss: {
																..._form.stoploss,
																target: _form.stoploss.target - 1,
															},
														};

														// Update the form fields with the updated form state
														dispatch(updateAllCurrentBasketFormFileds(_updateData));
													}
												}}
											>
												<RemoveIcon />
											</IconButton>
										</InputAdornment>
									),
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												onClick={(e) => {
													e.stopPropagation();

													// Create a copy of the form object
													const _form = { ...currentBasketFormData };

													// Update the stoploss.target field conditionally
													const _updateData = {
														..._form,
														stoploss: {
															..._form.stoploss,
															target: !isNaN(Number(_form.stoploss?.target))
																? Number(_form.stoploss.target) + 1
																: 1,  // If target is not a valid number, set it to 1
														},
													};

													// Update the form fields with the updated form state
													dispatch(updateAllCurrentBasketFormFileds(_updateData));
												}}
											>
												<AddIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</div>
					</div>
					<div className="w-full flex items-center justify-between">
						<Tooltip title="Delete Basket">
							<IconButton
								sx={{
									borderRadius: 1,
									"&:hover": {
										backgroundColor: "rgba(255, 0, 0, 0.1)", // Light red shade
									},
								}}
								onClick={() => {
									setOpenDeleteBasket(true);
								}}
								aria-label="delete"
							>
								<DeleteBaskterOrderIcon />
								{/* Replace with your DeleteBaskterOrderIcon if different */}
							</IconButton>
						</Tooltip>

						<div className="flex gap-3 ">
							<LoadingButton
								sx={{
									backgroundColor: "#FFFFFF",
									color: "#373737",
									border: "1px solid #D9D9D9",
									boxShadow: "none",
									"&:hover": {
										backgroundColor: "#FFFFFF",
										boxShadow: "none",
									},
								}}
								loading={isLoading}
								variant="contained"
								disabled={
									!currentBasketFormData?.name?.length > 0 ||
									margins?.required_margin > margins?.available_margin ||
									isExpired
								}
								onClick={handleClick}
							>
								Execute
							</LoadingButton>
							<LoadingButton
								loading={isLoading}
								sx={{
									boxShadow: "none",
									"&:hover": {
										boxShadow: "none",
									},
								}}
								variant="contained"
								disabled={!currentBasketFormData?.name?.length > 0}
								onClick={() => {
									if (currentBasket) {
										updateBasket(currentBasket?.id);
									} else {
										createBasket();
									}
								}}
							>
								Save
							</LoadingButton>
						</div>
					</div>
				</div>
			</div>
			<ConfirmDeleteBasket
				open={openDeleteBasket}
				onCloseBasket={() => setOpenDeleteBasket(false)}
				onRemoveBasket={() => deleteBasket(currentBasket?.id)}
				message={dialogueMessage}
				loading={isLoading}
			/>
		</>
	);
};

export default BasketForm;
