import React from "react";
import CompanyDetsComponent from "./CompanyDetsComponent";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { current } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const ColumnCell = ({ title }) => (
  <div className=" gap-2 px-2 rounded flex items-center justify-center">
    <h6 className="text-[#666666] text-xs font-semibold">{title}</h6>
  </div>
);

const Skeletons = Array.from({ length: 5 }).map((_, index) => (
  <Stack key={index} spacing={1}>
    <Skeleton animation="wave" variant="rectangular" width={300} height={160} />
    <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} />
    <Skeleton animation="wave" variant="text" sx={{ fontSize: "1rem" }} />
    <Skeleton animation="wave" variant="text" sx={{ fontSize: "2rem" }} />
  </Stack>
));

const CompaniesList = ({ spotRef, tc }) => {
  const { emaData, currentToolsIndex } = useSelector((state) => state.tools);
  const [currentIndexData, setCurrentIndexData] = React.useState(null)

  let _interval = emaData.interval
  let _currentIndex = currentToolsIndex
  let _emaEventName = `EMA${_currentIndex.toUpperCase()}minute${_interval}`

  React.useEffect(() => {
    for (let index = 0; index < spotRef.length; index++) {
      const _tc = spotRef[index];
      spotRef[index]?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [spotRef, tc]);

  React.useEffect(() => {

    setCurrentIndexData(emaData?.data[_emaEventName])
    // console.log(_emaEventName, 'ssss');
    // console.log(emaData?.data[_emaEventName]?.stocks, 'emdata stc');
    // console.log(emaData?.data, 'emdata');
  }, [emaData, currentToolsIndex]);

  // React.useEffect(() => {
  //   console.log(currentIndexData?.stocks, 'stock data ');
  // }, [currentIndexData])


  return (
    <>
      <div className="border bg-[#F9FBFC] flex flex-1">
        {currentIndexData?.stocks?.length > 0 ? (
          <>
            <div className="w-full flex-1 flex items-center">
              {currentIndexData?.stocks &&
                currentIndexData?.stocks.map((company, index) => {
                  // console.log(company, 'ssssss');
                  return (
                    <CompanyDetsComponent
                      title={company?.stock}
                      spotRef={spotRef[index]}
                      tc={tc[index]}
                      key={index}
                      company={company}
                    />
                  );
                })}
            </div>
          </>
        ) : (
          <div className="w-full flex justify-center gap-6 items-center  px-3 py-2 border-b border-b-[#e4dfdf]">
            {Skeletons}
          </div>
        )}
      </div>
    </>
  );
};

export default CompaniesList;
