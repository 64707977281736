import { Button, Divider, IconButton } from "@mui/material";
import { green, red, blue } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import PositionTotal from "./PositionTotal";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";

const LearnWithUsPos = ({ name, loss, onclick }) => (
	<div
		className="flex bg-[#00B3861A] rounded p-3 my-4 hover:shadow cursor-pointer transition-all"
		onClick={onclick}
	>
		<p className="text-[#00B386] font-normal text-sm text-center">
			{name}, your losses are <b className="text-red-500">&#8377;{loss}</b>{" "}
			today, <b>Join our courses</b> and turn your losses into profits.
		</p>
	</div>
);

const PositionButton = styled(Button)(({ theme }) => ({
	minWidth: "22px",
	height: "24px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: 12,
	borderRadius: 2,
}));

const _pos = [
	{
		_id: "NIFTY23D0720700PE",
		totalOrder: 1,
		fullFilledOrder: 1,
		masterOrderId: "656ebdf993ae7c1a6e878928",
		instrumentToken: 11063810,
		currentDate: "2023-12-05",
		date: "2023-12-05",
		currentquantity: 0,
		realizedProfitLoss: 0,
		lastTxnType: "BUY",
		lastOrderType: "MARKET",
		instrument_token: 11063810,
		price: 69.15,
		createdAt: "2023-12-05T06:06:49.644Z",
		dt: "05/12/23",
		strikePrice: 20754.45,
	},
];

const positionsInstruments = {};
const Position = ({
	data,
	currentPosition,
	admin,
	onAdd,
	onExit,
	openSlTarget,
	onRefresh,
	refresh,
	lossMsgClick,
	market,
	activeSL,
}) => {
	// console.log(data)
	data = data;
	// const [positionsInstruments, setPositionsInstruments] = React.useState({})
	const [loss, setLoss] = React.useState(0);
	const user = useSelector((state) => state.user);
	const { positionType } = useSelector((state) => state.orders);

	const returnLTP = (instrument) => {
		// console.log(market.positions, instrument)
		if (positionType == "basket") {
			return market.basketPositions && market.basketPositions?.[instrument]
				? market.basketPositions?.[instrument]?.last_price
				: 0;
		} else {
			return market.positions && market.positions?.[instrument]
				? market.positions?.[instrument]?.last_price
				: 0;
		}
		// if (market?.instruments && market?.instruments[`'${instrument}'`]) {
		//   positionsInstruments[instrument] = market?.[market?.instruments?.[`'${instrument}'`]?.index][market?.instruments?.[`'${instrument}'`]?.expiry
		//   ]?.options[market?.instruments?.[`'${instrument}'`]?.type][
		//     `'${instrument}'`
		//   ]?.last_price;
		//   return positionsInstruments[instrument];
		// }
	};

	return (
		<div className="lg:h-full w-full flex flex-col border-l-[0.5px] border-gray-100 flex-1 min-h-[300px]">
			<div className="h-full overflow-auto pb-20 px-2 flex-1">
				{data ? (
					data?.length ? (
						data?.map((pos, index) => (
							<div
								className={
									"px-3 py-2  max-w-full hover:shadow group transition ease-in-out duration-100 hover:bg-white "
								}
								key={index}
								style={{ borderBottom: "1px solid #eee" }}
							>
								<div
									className={
										"flex justify-between mb-1 h-7 items-center " +
										(!pos.currentquantity ? "pointer-events-none" : "")
									}
								>
									<div className="flex items-center gap-3">
										<p className="text-[#373737] font-semibold text-xs tracking-wide">
											QTY.{" "}
											<span className="text-blue-600">
												{pos?.currentquantity}
											</span>
										</p>
										{pos.currentquantity ? (
											<p className="text-gray-600 text-sm">
												LTP {returnLTP(pos?.instrumenttoken)}
											</p>
										) : null}
										{/* <p className='text-[#373737] text-xs tracking-wide'>@ <span className='text-blue-600'>{pos?.strikePrice}</span></p> */}
									</div>
									<>
										<div
											className={`gap-2 ${pos === currentPosition ? "flex" : "hidden"
												} transition ease-in-out duration-100 ${!pos.currentquantity
													? "group-hover:hidden"
													: "group-hover:flex"
												}`}
										>
											<PositionButton
												size="small"
												variant="contained"
												color="success"
												onClick={() => onAdd(pos)}
											>
												ADD
											</PositionButton>
											<PositionButton
												size="small"
												variant="contained"
												color="error"
												onClick={() => onExit(pos)}
											>
												EXIT
											</PositionButton>
										</div>
										{/* {pos?.hit ? pos?.hit : 'm'} */}
										{pos?.currentquantity == 0 ? (
											pos?.hit ? (
												// <SL hit={pos?.hit} active={activeSL} />
												pos?.hit.includes('sl') ? (
													<SL hit={pos?.hit} active={activeSL} />
												) : (
													<T hit={pos?.hit} active={activeSL} />
												)
											) : (
												<M />
											)
										) : null}
									</>
								</div>
								{/* { console.log(market[market.instruments[`${pos.instrumenttoken}`]?.index]) } */}
								<div className="flex justify-between mb-1 py-2">
									<p
										className={
											(!pos.currentquantity
												? "text-gray-400"
												: "text-blue-600") +
											" text-sm tracking-wide font-semibold hover:underline cursor-pointer"
										}
									>
										{pos?._id}
									</p>
									{market?.positions &&
										market?.positions[pos?.instrumenttoken] ? (
										pos.currentquantity ? (
											pos.currentquantity * returnLTP(pos.instrumenttoken) !=
												undefined ||
												pos.currentquantity * returnLTP(pos.instrumenttoken) !=
												null ? (
												-pos.realizedprofitloss +
													pos.currentquantity *
													returnLTP(pos.instrumenttoken) >=
													0 ? (
													<p className="text-green-600 font-semibold text-sm tracking-wide">
														{Math.abs(
															Math.abs(pos.realizedprofitloss) -
															Math.abs(pos.currentquantity) *
															returnLTP(pos.instrumenttoken)
														)?.toFixed(2)}
													</p>
												) : -pos.realizedprofitloss +
													pos.currentquantity *
													returnLTP(pos.instrumenttoken) <
													0 ? (
													<p className="text-red-600 font-semibold text-sm  tracking-wide">
														{Math.abs(
															Math.abs(pos.realizedprofitloss) -
															Math.abs(pos.currentquantity) *
															returnLTP(pos.instrumenttoken)
														)?.toFixed(2)}
													</p>
												) : (
													<Skeleton
														variant="text"
														width={30}
														sx={{ fontSize: "1rem" }}
													/>
												)
											) : (
												<Skeleton
													variant="text"
													width={30}
													sx={{ fontSize: "1rem" }}
												/>
											)
										) : pos?.realizedprofitloss != undefined ||
											pos?.realizedprofitloss != null ? (
											pos?.realizedprofitloss <= 0 ? (
												<p className="text-green-600 font-semibold text-sm tracking-wide">
													{Math.abs(pos.realizedprofitloss)?.toFixed(2)}
												</p>
											) : (
												<p className="text-red-600 font-semibold text-sm tracking-wide">
													{Math.abs(pos.realizedprofitloss)?.toFixed(2)}
												</p>
											)
										) : (
											<Skeleton
												variant="text"
												width={30}
												sx={{ fontSize: "1rem" }}
											/>
										)
									) : (
										<Skeleton
											variant="text"
											width={30}
											sx={{ fontSize: "1rem" }}
										/>
									)}
								</div>
								<div className="w-full flex items-center flex-wrap gap-2   py-1">
									<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px]  text-[11px] tracking-wide">
										ENT L.{" "}
										<span className="text-black-600 font-bold">
											{pos?.entry_ltp ? pos?.entry_ltp?.toLocaleString("en-US", { minimumFractionDigits: 2 }) : "--"}
										</span>
									</p>
									<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
										EX L.{" "}
										<span className="text-black-600 font-bold">
											{pos?.exit_ltp ? pos?.exit_ltp?.toLocaleString("en-US", { minimumFractionDigits: 2 }) : "--"}
										</span>
									</p>
									<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
										ENT I.{" "}
										<span className="text-black-600 font-bold">
											{pos?.entry_index ? pos?.entry_index?.toLocaleString("en-US", { minimumFractionDigits: 2 }) : "--"}
										</span>
									</p>
									<p className="text-[#373737] bg-gray-100 p-1 px-2 rounded-[2px] text-[11px] tracking-wide">
										EX I.{" "}
										<span className="text-black-600 font-bold">
											{pos?.exit_index ? pos?.exit_index?.toLocaleString("en-US", { minimumFractionDigits: 2 }) : "--"}
										</span>
									</p>
								</div>
								<div className="flex justify-between mb-1">
									{/* <div className='flex gap-4'>
                                <p className='text-[#373737] text-xs tracking-wide'>OF: <span className='text-black-600 font-bold'>{pos?.fullFilledOrder}</span>/{pos?.totalOrder}</p>
                            </div> */}
								</div>
							</div>
						))
					) : (
						<div className="w-full h-full flex justify-center items-center">
							<>
								<p className="text-center">NO POSITIONS FOUND!</p>
							</>
						</div>
					)
				) : (
					<div className="w-full h-full">
						{[1, 2, 3, 4, 5].map((count) => (
							<div
								key={count}
								className="px-3 py-2 max-w-[350px] transition ease-in-out duration-100 hover:bg-white"
								style={{ borderBottom: "1px solid #eee" }}
							>
								<div className="flex justify-between mb-1 h-7 items-center">
									<Skeleton
										variant="text"
										width={35}
										sx={{ fontSize: "1rem" }}
									/>
									<div
										className={`gap-2 transition ease-in-out duration-100 flex`}
									>
										<Skeleton variant="rectangular" width={65} height={30} />
										<Skeleton variant="rectangular" width={65} height={30} />
									</div>
								</div>
								<div className="flex justify-between mb-1">
									<Skeleton
										variant="text"
										width={"80%"}
										sx={{ fontSize: "1rem" }}
									/>
									<Skeleton
										variant="text"
										width={50}
										sx={{ fontSize: "1rem" }}
									/>
								</div>
								<div className="flex justify-between mb-1">
									<div className="flex gap-4">
										<Skeleton
											variant="text"
											width={35}
											sx={{ fontSize: "1rem" }}
										/>
										<Skeleton
											variant="text"
											width={35}
											sx={{ fontSize: "1rem" }}
										/>
										<Skeleton
											variant="text"
											width={35}
											sx={{ fontSize: "1rem" }}
										/>
									</div>
									<Skeleton
										variant="text"
										width={75}
										sx={{ fontSize: "1rem" }}
									/>
								</div>
							</div>
						))}
					</div>
				)}
				{loss && !admin ? (
					<LearnWithUsPos
						name={user?.name?.split(" ")[0]}
						loss={loss}
						onclick={lossMsgClick}
					/>
				) : null}
				<div className="w-full py-6"></div>
			</div>
			<PositionTotal
				positions={data}
				marketPositions={market.positions}
				pl={positionsInstruments}
				hasLoss={(value) => setLoss(value)}
			/>
		</div>
	);
};

export default Position;

const SL = ({ active, hit }) => (
	<div className="bg-red-100 px-2 py-1 min-w-[40px] rounded-sm">
		<p className="text-[10px] font-bold uppercase tracking-widest text-center text-red-500">
			{hit}
		</p>
	</div>
);

const T = ({ active, hit }) => (
	<div className="bg-green-200 bg-opacity-40 px-2 py-1 min-w-[40px] rounded-sm">
		<p className="text-[10px] font-bold uppercase tracking-widest text-center text-blue-500">
			{hit}
		</p>
	</div>
);

const M = () => (
	<div className="bg-blue-100  px-2 py-1 min-w-[40px] rounded-sm">
		<p className="text-[10px] font-bold tracking-widest text-center text-blue-500">
			M
		</p>
	</div>
);
