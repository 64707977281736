import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import { useSelector } from "react-redux";
import BrokerNotConn from "./BrokerNotConn";
import StepperDialogue from "./StepperDialogue";
import { Avatar, Box, Button, IconButton, ListItemIcon } from "@mui/material";
import {
  HistoryOutlined,
  SettingsOutlined,
  WorkHistory,
} from "@mui/icons-material";
import { useDimensions } from "../Contexts/DimensionContext/DimensionsContext";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import GroupsIcon from "@mui/icons-material/Groups";
import { ApiService, http } from "../Services/api.services";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HandymanIcon from "@mui/icons-material/Handyman";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { observer } from "mobx-react-lite";
import Logo from "../assets/fastr_logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import ErrorIcon from "@mui/icons-material/Error";
import DoneIcon from "@mui/icons-material/Done";
import { EditOutlined, SearchOutlined } from "@material-ui/icons";
import { Logout } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";

import Searchcomponent from "../EquityComponents/Searchcomponent";

// import { useSamcoSocket } from '../WebsocketContext/SamcoWSContext';
const selectedRoute =
  "text-[#373737] font-semibold text-[13px] uppercase m-0";
const notSelected =
  "text-[#757575] font-normal uppercase text-[13px] m-0";
const disabledRoute =
  "text-[#C0C0C0CA] font-normal uppercase text-[13px] m-0";

// const admin = true;
const api = new ApiService();

const HomeLayout = () => {
  const _location = useLocation();
  // const { receivedMessages } = useSamcoSocket();

  // React.useEffect(() => {
  //     console.log('SAMCO -- ', receivedMessages)
  // }, [receivedMessages])

  const { xs, sm, md, lg, xl, xxl } = useDimensions();
  const features = useSelector((state) => state.features);
  const navigate = useNavigate();
  const home = useSelector((state) => state.optionHome);
  const { user } = useSelector((state) => state.user);
  const [openBroker, setOpenBroker] = React.useState(false);
  const [totalUsersCount, setTotalUsersCount] = React.useState(null);
  const [totalActiveUsersCount, setTotalActiveUsersCount] =
    React.useState(null);
  // const { plans } = useSelector((state) => state.plans);
  const [brokers, setBrokers] = React.useState(home.brokers);
  const { admin } = useSelector((state) => state.admin);

  const [openStepper, setOpenStepper] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  React.useEffect(() => {
    setBrokers(home.brokers);
  }, [home.brokers]);

  // CONTECT BROKER
  const connectBroker = () => {
    window.location.href = localStorage.getItem("auth-connect");
  };

  React.useEffect(() => {
    getUsersCount();
  }, []);

  const getUsersCount = async () => {
    try {
      const response = await http.get("/admin/private-user/count");
      if (response?.status == 200) {
        setTotalUsersCount(response?.data?.data?.total);
        setTotalActiveUsersCount(response?.data?.data?.active);
      }
    } catch (e) {
      console.log(e + "ERROR WHILE FETCHING USERS COUNT!");
    }
  };

  // PATH FOR THE NAVIGATION
  const naviagtePath = admin ? "/admin/options" : "/index-options";

  const settingRoute = admin
    ? "/admin/options/settings"
    : "/index-options/settings";
  const indicatorRoute = admin
    ? "/admin/options/indicators"
    : "/index-options/indicators";
  const indexIndicatorRoute = admin
    ? "/admin/options/index"
    : "/index-options/index";
  const ordersRoute = admin ? "/admin/options/orders" : "/index-options/orders";
  const strikesRoute = admin
    ? "/admin/options/strikes"
    : "/index-options/strikes";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    console.log(event.currentTarget);
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const updateBroker = () => {
    setOpenBroker(true);
    handleCloseUserMenu();
  };

  const handleLogout = async () => {
    const token = localStorage.getItem("token");
    const response = await api.logout({ token });
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="w-full overflow-hidden flex-1  bg-[#fafdff]">
      <div className="hidden lg:flex items-center justify-between bg-white shadow py-1 px-3 w-full">
        <div className="flex items-center gap-10">
          <div className="flex items-center gap-4">
            <img style={{ height: 32 }} src={Logo} alt="fastr.png" />
            <div className="flex items-center gap-2 ">
              <Button
                id="basic-button"
                aria-controls={open1 ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
                endIcon={<ArrowDropDownIcon />}
                size="small"
                variant="outlined"
              >
                {home.currentModule}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose1();
                    navigate("/admin/equity/in-progress");
                  }}
                >
                  Equity
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose1();
                    navigate("/admin/options/strikes");
                  }}
                >
                  Options
                </MenuItem>
              </Menu>
              <div className="w-[1px] h-8 bg-gray-300"></div>
            </div>
            <>
              <Tooltip title="Exchanges">
                <Button
                  onClick={() => navigate(`${naviagtePath}/exchanges`)}
                  variant="text"
                  size="small"
                  disableRipple
                >
                  <h6
                    className={
                      home.activePath === "exchanges"
                        ? selectedRoute
                        : notSelected
                    }
                  >
                    EXCHANGES
                  </h6>
                </Button>
              </Tooltip>
              <Tooltip title="Indexes">
                <Button
                  onClick={() => navigate(`${naviagtePath}/indexes`)}
                  variant="text"
                  size="small"
                  disableRipple
                >
                  <h6
                    className={
                      home.activePath === "indexes"
                        ? selectedRoute
                        : notSelected
                    }
                  >
                    Indexes
                  </h6>
                </Button>
              </Tooltip>
              <Tooltip title="Strikes">
                <Button
                  onClick={() => navigate(`${naviagtePath}/strikes`)}
                  variant="text"
                  size="small"
                  disableRipple
                >
                  <h6
                    className={
                      home.activePath === "strikes"
                        ? selectedRoute
                        : notSelected
                    }
                  >
                    Strikes
                  </h6>
                </Button>
              </Tooltip>
              <Tooltip title={_location.pathname.includes('admin') ? "Basket" : 'Coming Soon'}>
                <Button
                  onClick={() => {
                    // console.log('jeuuuuu');

                    if (_location?.pathname?.includes('admin')) {
                      // console.log('jeuuuuu');
                      navigate(`${naviagtePath}/basket`);
                    }
                  }}
                  variant="text"
                  size="small"
                  // disabled={!_location.pathname.includes('admin') ? true : false}
                  disableRipple
                >
                  <h6
                    className={
                      _location.pathname.includes('admin')
                        ? home.activePath === 'basket'
                          ? selectedRoute
                          : notSelected
                        : disabledRoute
                    }
                  >
                    Basket
                  </h6>
                </Button>
              </Tooltip>
            </>
          </div>
        </div>
        <div className="flex items-center gap-4">
          {admin ? (
            <Tooltip className="lg:gap-2" title="Orders">
              <Button
                variant="text"
                size="small"
                onClick={() => navigate("/admin/options/users")}
              >
                <GroupsIcon
                  fontSize="small"
                  sx={{
                    color:
                      home.activePath === "users" ? "#262626" : "#757575",
                  }}
                />
                {/* <h6 className={home.activePath === 'users' ? selectedRoute : notSelected}>Users {totalActiveUsersCount && totalUsersCount ? ({ totalActiveUsersCount } / { totalUsersCount }) : null} </h6> */}
                <h6
                  className={
                    home.activePath === "users" ? selectedRoute : notSelected
                  }
                >
                  Users{" "}
                  {totalActiveUsersCount && totalUsersCount
                    ? `(${totalActiveUsersCount} / ${totalUsersCount})`
                    : null}
                </h6>
              </Button>
            </Tooltip>
          ) : null}

          <Tooltip className="lg:gap-2" title="Orders">
            <Button
              variant="text"
              size="small"
              onClick={() => navigate(`${naviagtePath}/orders`)}
            >
              <HistoryOutlined
                fontSize="small"
                sx={{
                  color: home.activePath === "orders" ? "#262626" : "#757575",
                }}
              />
              <h6
                className={
                  home.activePath === "orders" ? selectedRoute : notSelected
                }
              >
                ORDERS
              </h6>
            </Button>
          </Tooltip>

          {(features?.ADVANCED_STOPLOSS_SETTING &&
            features?.ADVANCED_TARGET_PRICE_SETTING) ||
            admin ? (
            <>
              <Tooltip className="lg:gap-2" title="Technical Indicators">
                <Button variant="text" size="small" onClick={handleClick}>
                  <HandymanIcon
                    fontSize="small"
                    sx={{
                      color: home?.paths?.includes("tools")
                        ? "#262626"
                        : "#757575",
                    }}
                  />
                  <h6
                    className={
                      home?.paths?.includes("tools")
                        ? selectedRoute
                        : notSelected
                    }
                  >
                    Tools
                  </h6>
                </Button>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate(`${naviagtePath}/tools/index`);
                    handleClose();
                  }}
                >
                  <IconButton aria-label="Index Indicator">
                    <CandlestickChartIcon
                      fontSize="small"
                      sx={{ color: "#262626" }}
                    />
                  </IconButton>
                  Index Indicator
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(`${naviagtePath}/tools/vwap`);
                    handleClose();
                  }}
                >
                  <IconButton aria-label="VWAP">
                    <TrendingUpIcon
                      fontSize="small"
                      sx={{ color: "#262626" }}
                    />
                  </IconButton>
                  Anchored VWAP
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate(`${naviagtePath}/tools/actions`);
                    handleClose();
                  }}
                >
                  <IconButton aria-label="Actions">
                    <TrendingUpIcon
                      fontSize="small"
                      sx={{ color: "#262626" }}
                    />
                  </IconButton>
                  Actions
                </MenuItem>
              </Menu>
            </>
          ) : null}

          {admin ? (
            (features?.ADVANCED_STOPLOSS_SETTING &&
              features?.ADVANCED_TARGET_PRICE_SETTING) ||
              admin ? (
              <Tooltip className="lg:gap-2" title="Technical Indicators">
                <Button
                  variant="text"
                  size="small"
                  onClick={() => navigate(`${naviagtePath}/indicators`)}
                >
                  <CandlestickChartIcon
                    fontSize="small"
                    sx={{
                      color:
                        home.activePath === "indicators"
                          ? "#262626"
                          : "#757575",
                    }}
                  />
                  <h6
                    className={
                      home.activePath === "indicators"
                        ? selectedRoute
                        : notSelected
                    }
                  >
                    Indicators
                  </h6>
                </Button>
              </Tooltip>
            ) : null
          ) : (
            <Tooltip className="lg:gap-2" title="Coming Soon">
              <Button variant="text" size="small" disableRipple>
                <WorkHistory sx={{ color: "#c0c0c0ca" }} />
                <h6 className={disabledRoute}>PORTFOLIO</h6>
              </Button>
            </Tooltip>
          )}

          {(features?.ADVANCED_STOPLOSS_SETTING &&
            features?.ADVANCED_TARGET_PRICE_SETTING) ||
            admin ? (
            <Tooltip className="lg:gap-2" title="Settings">
              <Button
                variant="text"
                size="small"
                onClick={() => navigate(`${naviagtePath}/settings`)}
              >
                <SettingsOutlined
                  fontSize="small"
                  sx={{
                    color:
                      home.activePath === "settings" ? "#262626" : "#757575",
                  }}
                />
                <h6
                  className={
                    home.activePath === "settings"
                      ? selectedRoute
                      : notSelected
                  }
                >
                  Settings
                </h6>
              </Button>
            </Tooltip>
          ) : null}

          {/* AVATAR AND LOGOUT */}
          {md || lg || xl || xxl ? (
            <div className="flex  items-center gap-1 lg:gap-5">
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <div className="relative">
                      <div
                        className={`w-3 h-3 rounded-full absolute top-0 right-0 z-50 ${admin?.active || user?.broker[0]?.active ? "bg-green-600" : "bg-red-600"
                          }`}
                      ></div>
                      <Avatar
                        alt={user?.name}
                        src="/static/images/avatar/2.jpg"
                      />
                    </div>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {admin?.active || user?.broker[0]?.active ? (
                    <MenuItem className="!bg-green-50">
                      <ListItemIcon>
                        <DoneIcon color="success" fontSize="small" />
                      </ListItemIcon>
                      <span className="text-slate-500">Broker Connected</span>
                    </MenuItem>
                  ) : (
                    <MenuItem onClick={connectBroker} className="!bg-red-50">
                      <ListItemIcon>
                        <ErrorIcon color="error" fontSize="small" />
                      </ListItemIcon>
                      <span className="text-slate-500">Not Connected</span>
                    </MenuItem>
                  )}
                  {user?.broker?.length ? (
                    <MenuItem onClick={() => updateBroker()}>
                      <ListItemIcon>
                        <EditOutlined color="primary" fontSize="small" />
                      </ListItemIcon>
                      <span className="text-slate-500">Update Broker</span>
                    </MenuItem>
                  ) : null}
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout color="error" fontSize="small" />
                    </ListItemIcon>
                    <span className="text-red-400">Logout</span>
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          ) : (
            <div className="w-full flex items-center justify-between ">
              <div className="flex items-center gap-2">
                <p className="text-[#A4A4A4] text-sm">EQUITY</p>
                <p className="text-[#A4A4A4] text-sm">INDEX OPTIONS</p>
              </div>
              <IconButton onClick={() => setDrawerOpen(true)}>
                <MenuIcon color="primary" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-[calc(100%-72px)] lg:h-[calc(100%-48px)] overflow-hidden relative flex flex-col">
        <Outlet />

        {admin ? (
          <h1></h1>
        ) : user && !user.broker ? (
          <BrokerNotConn
            message={"PLEASE WAIT ..."}
            type={"WAIT"}
            loading={true}
          />
        ) : user?.broker && !user.broker.length ? (
          <BrokerNotConn
            msg={
              "Congratulations! You're just one step away from your trading journey."
            }
            message={"ADD YOUR BROKER"}
            type={"ADD BROKER"}
            onConnect={() => setOpenStepper(true)}
          />
        ) : user?.broker?.length && !user?.broker[0]?.active ? (
          <BrokerNotConn
            message={"CONNECT YOUR BROKER"}
            type={"CONNECT"}
            onConnect={() => connectBroker()}
          />
        ) : null}
      </div>

      {
        xs || sm || md ? (
          <AppBar
            position="fixed"
            color="secondary"
            sx={{
              top: "auto",
              bottom: 0,
              backgroundColor: "white",
              height: 64,
              boxShadow: "none",
              zIndex: "999999",
            }}
            className="rounded-tl-3xl rounded-tr-3xl"
          >
            <Toolbar className="flex items-center justify-between py-5">
              {home.activePath === "exchanges" ? (
                <div
                  className="flex items-center rounded-full px-4 py-2 gap-1"
                  style={{
                    background:
                      "linear-gradient(180deg, #373737 -103.13%, #373737 14%, #373737 86.8%, rgba(217, 217, 217, 0.00) 180.28%, rgba(217, 217, 217, 0.00) 205.78%, #373737 311.83%)",
                  }}
                >
                  <SwapHorizIcon className="text-white !text-[30px]" />
                  <h6 className="text-white m-0 font-normal">
                    Exchanges
                  </h6>
                </div>
              ) : (
                <Tooltip title="EXCHANGES COMING SOON" enterTouchDelay={0}>
                  <SwapHorizIcon className="text-[#A4A4A4] !text-[30px]" />
                </Tooltip>
              )}
              {home.activePath === "indexes" ? (
                <div
                  className="flex items-center rounded-full px-4 py-2 gap-1"
                  style={{
                    background:
                      "linear-gradient(180deg, #373737 -103.13%, #373737 14%, #373737 86.8%, rgba(217, 217, 217, 0.00) 180.28%, rgba(217, 217, 217, 0.00) 205.78%, #373737 311.83%)",
                  }}
                >
                  <SwapHorizIcon className="text-white !text-[30px]" />
                  <h6 className="text-white m-0 font-normal">
                    Indexes
                  </h6>
                </div>
              ) : (
                <Tooltip title="INDEXES COMING SOON" enterTouchDelay={0}>
                  <SwapHorizIcon className="text-[#A4A4A4] !text-[30px]" />
                </Tooltip>
              )}
              {home.activePath === "strikes" ? (
                <Button
                  className="!rounded-3xl"
                  size={xs ? "small" : sm ? "medium" : "large"}
                  variant="contained"
                  color="primary"
                >
                  <SwapCallsIcon />
                  Strike
                </Button>
              ) : (
                <IconButton onClick={() => navigate(strikesRoute)}>
                  <SwapCallsIcon />
                </IconButton>
              )}

              {admin ? (
                home.activePath === "users" ? (
                  <Button
                    className="!rounded-3xl"
                    size={xs ? "small" : sm ? "medium" : "large"}
                    variant="contained"
                    color="primary"
                  >
                    <GroupsIcon />
                    Users
                  </Button>
                ) : (
                  <IconButton onClick={() => navigate("/admin/options/users")}>
                    <GroupsIcon />
                  </IconButton>
                )
              ) : null}
              {home.activePath === "orders" ? (
                <Button
                  className="!rounded-3xl"
                  size={xs ? "small" : sm ? "medium" : "large"}
                  variant="contained"
                  color="primary"
                >
                  <HistoryOutlined />
                  Orders
                </Button>
              ) : (
                <IconButton onClick={() => navigate(ordersRoute)}>
                  <HistoryOutlined />
                </IconButton>
              )}
              {(features?.ADVANCED_STOPLOSS_SETTING &&
                features?.ADVANCED_TARGET_PRICE_SETTING) ||
                admin ? (
                home.activePath === "indicators" ? (
                  <Button
                    className="!rounded-3xl"
                    size={xs ? "small" : sm ? "medium" : "large"}
                    variant="contained"
                    color="primary"
                  >
                    <CandlestickChartIcon />
                    Indicators
                  </Button>
                ) : (
                  <IconButton onClick={() => navigate(indicatorRoute)}>
                    <CandlestickChartIcon />
                  </IconButton>
                )
              ) : null}
              {(features?.ADVANCED_STOPLOSS_SETTING &&
                features?.ADVANCED_TARGET_PRICE_SETTING) ||
                admin ? (
                home.activePath === "index" ? (
                  <Button
                    className="!rounded-3xl"
                    size={xs ? "small" : sm ? "medium" : "large"}
                    variant="contained"
                    color="primary"
                  >
                    <CandlestickChartIcon />
                    Index Indicators
                  </Button>
                ) : (
                  <IconButton onClick={() => navigate(indexIndicatorRoute)}>
                    <CandlestickChartIcon />
                  </IconButton>
                )
              ) : null}
              {/* <Tooltip className='lg:gap-2' title="Technical Indicators">
                      <Button variant='text' size='small' onClick={handleClick}>
                      <HandymanIcon fontSize='small' sx={{ color: home?.paths?.includes('tools') ? '#262626' : '#757575' }} />
                      <h6 className={home?.paths?.includes('tools') ? selectedRoute : notSelected}>Tools</h6>
                      </Button>
                      </Tooltip> */}
              {((features?.ADVANCED_STOPLOSS_SETTING &&
                features?.ADVANCED_TARGET_PRICE_SETTING) ||
                admin) &&
                (home?.paths?.includes("tools") ? (
                  <Button
                    className="!rounded-3xl"
                    size={xs ? "small" : sm ? "medium" : "large"}
                    variant="contained"
                    color="primary"
                  >
                    <HandymanIcon />
                    Tools
                  </Button>
                ) : (
                  <IconButton onClick={() => navigate(indexIndicatorRoute)}>
                    <HandymanIcon />
                  </IconButton>
                ))}

              {/* <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                          'aria-labelledby': 'basic-button',
                          }}
                          >
                          <MenuItem
                          onClick={() => {
                            navigate(`${navigatePath}/tools/index`); // Corrected navigatePath spelling
                          handleClose();
                          }}
                          >
                          <IconButton aria-label="Index Indicator">
                          <CandlestickChartIcon fontSize='small' sx={{ color: '#262626' }} />
                          </IconButton>
                          Index Indicator
                          </MenuItem>
                          <MenuItem
                          onClick={() => {
                            navigate(`${navigatePath}/tools/vwap`); // Corrected navigatePath spelling
                          handleClose();
                          }}
                          >
                          <IconButton aria-label="VWAP">
                          <TrendingUpIcon fontSize='small' sx={{ color: '#262626' }} />
                          </IconButton>
                          VWAP Aggregate
                          </MenuItem>
                          <MenuItem
                          onClick={() => {
                            navigate(`${navigatePath}/tools/actions`); // Corrected navigatePath spelling
                          handleClose();
                          }}
                          >
                          <IconButton aria-label="Actions">
                          <TrendingUpIcon fontSize='small' sx={{ color: '#262626' }} />
                          </IconButton>
                          Actions
                          </MenuItem>
                          </Menu> */}

              {(features?.ADVANCED_STOPLOSS_SETTING &&
                features?.ADVANCED_TARGET_PRICE_SETTING) ||
                admin ? (
                home.activePath === "settings" ? (
                  <Button
                    className="!rounded-3xl"
                    size={xs ? "small" : sm ? "medium" : "large"}
                    variant="contained"
                    color="primary"
                  >
                    <SettingsOutlined />
                    Settings
                  </Button>
                ) : (
                  <IconButton onClick={() => navigate(settingRoute)}>
                    <SettingsOutlined />
                  </IconButton>
                )
              ) : null}
              {!admin ? (
                <Tooltip title="PORTFOLIO COMING SOON" enterTouchDelay={0}>
                  <IconButton disabled>
                    <WorkHistory />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Toolbar>
          </AppBar>
        ) : null
      }

      {
        openStepper ? (
          <StepperDialogue
            open={openStepper}
            setOpenStepper={setOpenStepper}
            user={user}
            brokers={brokers}
            onClose={() => {
              setOpenStepper(false);
              handleCloseUserMenu();
            }}
          />
        ) : null
      }
    </div >
  );
};

export default observer(HomeLayout);
