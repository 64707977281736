import React from "react";
import { BasketPositionTotal, HistoryCard } from "./BasketCard";
import PositionTotal from "../../PublicComponents/PositionTotal";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { http } from "../../Services/api.services";
import { useDispatch, useSelector } from "react-redux";
import {
	updateBaskethistory,
	updateBasketStatus,
	updateCurrentHistory,
} from "../../store/Reducer/BasketReducer";
import { BasketSkelatons } from "./BasketSkelatons";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";

const Skeletons = Array.from({ length: 7 }).map((_, index) => (
	<Stack
		sx={{
			padding: 3,
		}}
		key={index}
		spacing={0}
	>
		<div className="flex items-center justify-between">
			<Skeleton
				animation="wave"
				variant="text"
				width="40%"
				sx={{ fontSize: "1rem" }}
			/>
			<Skeleton
				animation="wave"
				variant="text"
				width="7%"
				sx={{ fontSize: "2rem" }}
			/>
		</div>
		<div className="flex w-full items-center justify-between">
			<div className="flex w-full items-center gap-5">
				<Skeleton
					animation="wave"
					variant="text"
					width="10%"
					sx={{ fontSize: "1rem" }}
				/>
				<Skeleton
					animation="wave"
					variant="text"
					width="10%"
					sx={{ fontSize: "1rem" }}
				/>
			</div>
			<Skeleton
				animation="wave"
				variant="text"
				width="10%"
				sx={{ fontSize: "1rem" }}
			/>
		</div>
	</Stack>
));

const BasketHistory = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const { admin } = useSelector((state) => state.admin);
	const { basketHistory, currentHistory } = useSelector(
		(state) => state.Baskets
	);
	const id = admin ? admin?.id : user?.id;
	const [finalPL, setFinalPL] = React.useState(0);

	// CALCULATE TOITAL PL OF HISTORY
	React.useEffect(() => {
		if (basketHistory) {
			const _allHistory = basketHistory ? [...basketHistory] : [];
			let _finalPL = 0;

			_allHistory.forEach((history) => {
				let _PL = history?.profit_loss || 0;
				// console.log(_PL)
				// Ensure _PL is a fixed decimal value
				_PL = parseFloat(_PL?.toFixed(2));

				// Add the profit/loss to _totalPL
				_finalPL += _PL;
			});
			// console.log(_finalPL);
			setFinalPL(_finalPL * -1);
		}
	}, [basketHistory, dispatch]);

	const fetchHistoryLegs = async (history) => {
		try {
			const response = await http.get(`orders/basket/history/${history?.id}`);
			if (response?.status == 200) {
				// dispatch(updateBaskethistory(response?.data?.data));
				// console.log(response.data?.data)
				dispatch(updateCurrentHistory({ ...history, orders: response.data?.data }));
			}
		}
		catch (e) {
			console.log(`ERRR WHILE FETCHING BASKET HISTORY LEGS -- ${e}`)
		}
	}

	return (
		<>
			<div className="relative w-full h-full flex flex-col ">
				{/* <div className="w-full  px-3 py-2">{Skeletons}</div> */}
				<div className="w-full flex flex-col flex-1 overflow-y-auto pb-20 items-start  p-4 px-0">
					{basketHistory ? (
						basketHistory?.length > 0 ? (
							basketHistory?.map((history, index) => {
								return (
									<HistoryCard
										history={history}
										key={index}
										selectHistory={() => {
											fetchHistoryLegs(history);
											dispatch(updateBasketStatus("history selected"));
										}}
									/>
								);
							})
						) : (
							<div className="w-full h-full flex justify-center items-center">
								<>
									<p className="text-center">NO HISTORY FOUND!</p>
								</>
							</div>
						)
					) : (
						<BasketSkelatons />
					)}
				</div>
				<div className="w-full absolute bottom-0 z-50">
					<BasketPositionTotal finalPL={finalPL} />
				</div>
			</div>
		</>
	);
};

export default BasketHistory;
