import React from "react";
import SLChart from "./SLChart";
import CompanyGraph from "./CompanyGraph";
import { useSelector } from "react-redux";

// const HeadCell = ({ label, color, value }) => (
//     <div className='w-full border-b border-b-[#e4dfdf] flex flex-col items-center justify-center gap-2 py-2'>
//         <div className='w-fit border gap-2 px-2 border-[#e4dfdf] rounded flex items-center justify-between'>
//             <h6 style={{ color: color }} className='p-1 border-r border-r-[#e4dfdf] text-xs uppercase'>{label}</h6>
//             <small className='text-[#8F8F8F] text-xs'>{value.toFixed(2)}</small>
//         </div>
//     </div>
// )
const HeadCell = ({ label, color, value, ref }) => (
  <div className="w-full border-b border-b-[#eae8e8] flex flex-col items-center justify-center gap-2 py-2">
    <div
      className={`w-fit gap-2 border px-2 ${label === "spot" ? "border-[#B23AEB]" : "border-[#eae8e8]"
        } rounded flex items-center justify-between`}
    >
      <h6
        style={{ color: color }}
        className={`p-1 border-r ${label === "spot" ? "border-r-[#B23AEB]" : "border-r-[#eae8e8]"
          }   text-xs uppercase`}
      >
        {label}
      </h6>
      <small className="text-[#8F8F8F] text-xs">{value?.toFixed(2)}</small>
    </div>
  </div>
);

const CompanyDetsComponent = ({ company, spotRef, tc, title }) => {

  // FUNCTION TO SCROLL TABLE ON PAGE REFRESH
  const { companiesStockData, currentToolsIndex, slChartData } = useSelector(
    (state) => state.tools
  );
  const [currentCompaniesValues, setCurrentCompaniesValues] = React.useState("");

  React?.useEffect(() => {
    const currentIndexData = companiesStockData.find(
      (item) => item?.index?.toLowerCase() === currentToolsIndex?.toLowerCase()
    );

    const _currentCompanyValues = currentIndexData?.stock.find(
      (item) => item?.company?.toLowerCase() === title?.toLowerCase()
    );

    setCurrentCompaniesValues(_currentCompanyValues);

  }, [companiesStockData, currentToolsIndex, title, company]);


  return (
    <div className="flex flex-col flex-1">
      <div className="px-2 py-2 rounded flex items-center justify-center">
        <h6 className="text-[#666666] text-xs font-semibold">{title}</h6>
      </div>
      <div className="border-r w-full  border-t border-b border-[#e4dfdf] flex-1">
        <CompanyGraph
          title={title}
          data={company?.slc}
          graphValues={currentCompaniesValues}
        />
      </div>
      <div
        ref={tc}
        className="w-full flex flex-col items-center overflow-y-auto gap-2 py-2 h-[150px]"
      >
        {company?.ema &&
          company?.ema.map((el, idx) => {
            return (
              <div
                key={idx}
                ref={el?.label == "spot" ? spotRef : null}
                className="w-full"
              >
                <HeadCell
                  label={el?.label}
                  color={el?.color}
                  value={el?.value}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CompanyDetsComponent;
