import React, { useEffect } from 'react'
import Header from '../PublicComponents/Header'
import SingleCard from "../PublicComponents/SingleCard"
import { EVENT_SOURCE_URL } from '../default.config';
import { useDispatch, useSelector } from 'react-redux';
import { updateIndex, updateIndexCandleData } from '../store/Reducer/IndexExchangeReducer';
import OIChange from '../PublicComponents/OiChange';

const Indexes = () => {
    const dispatch = useDispatch()
    const { indexCandleData, currentIndex } = useSelector((state) => state.indexExchange)

    useEffect(() => {
        let emaEventSource;
        let _indexes = [
            "NIFTY",
            "BANKNIFTY",
            "FINNIFTY",
            "MIDCPNIFTY",
            "SENSEX",
            "BANKEX",
        ];
        let _intervals = [1, 3, 5, 10, 15];
        try {
            const baseUrl = `${EVENT_SOURCE_URL}ds`;
            const emaEventSourseUrl = `${baseUrl}/minute-index-candles`;
            emaEventSource = new EventSource(emaEventSourseUrl);

            for (let i = 0; i < _indexes.length; i++) {
                for (let j = 0; j < _intervals.length; j++) {
                    let eventName = `${_indexes[i]?.toLowerCase()}index${_intervals[j]}`;
                    emaEventSource.addEventListener(eventName, (event) => {
                        const data = JSON.parse(event.data); // Ensure the data is parsed correctly
                        // console.log(JSON.parse(event.data), "ssss", eventName);
                        dispatch(updateIndexCandleData({ eventName, data }));
                    });
                }
                // console.log(element, "element");
            }

            emaEventSource.onmessage = (event) => {
                console.log("MESSAGE FROM SSE -- ", event?.data);
            };

            emaEventSource.onopen = (event) => {
                console.log("OPEN SSE -- ", event);
            };

            emaEventSource.onerror = (event) => {
                console.log("ERROR SSE -- ", event);
                if (emaEventSource.readyState === EventSource.CLOSED) {
                    console.log("EventSource connection closed.");
                }
            };
        } catch (e) {
            console.log(`ERROR WHILE FETCHING IHM DATA: ${e}`);
        }

        return () => {
            if (emaEventSource) {
                emaEventSource.close();
            }
        };
    }, []);


    const eventName = `${currentIndex}index${1}`;
    const __data = indexCandleData?.[eventName];

    return (
        <>
            <Header
                currentIndex={currentIndex}
                expiry={true}
                onIndexChange={(value) => {
                    dispatch(updateIndex(value))
                }}
            />
            <div className='CardDiv w-full flex flex-wrap items-center py-4 px-2 justify-between gap-5 lg:gap-10 max-h-[55vh] lg:max-h-[65vh] overflow-y-auto'>
                {__data?.o} <br />
                {__data?.h}<br />
                {__data?.l}<br />
                {__data?.c}<br />
                {eventName}
                <OIChange
                    // oiFactor={oiFactor}
                    updateOIFactor={(oi) => {
                        // console.log('OI UPDATED', oi)
                        // onOIFactorUpdated(oi)
                    }}
                    // updateOIFactor={(oi) => setOIFactor(oi)}
                    // oi={data[currentExpiry].putOI}
                    high={__data?.h}
                    low={__data?.l}
                    close={__data?.c}
                    open={__data?.o}
                // qty={data?.qty}
                />

                {/* <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard />
                <SingleCard /> */}
            </div>
        </>
    )
}

export default Indexes