import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/styles";
import { useMarketContext } from "../../Contexts/MarketContext/MarketContext";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { LegsCard } from "./BasketCard";
import {
	updateAllCurrentBasketFormFileds,
	updateCurrentBasketFormData,
	updateCurrentPosition,
	updateMargins,
} from "../../store/Reducer/BasketReducer";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InputAdornment from "@mui/material/InputAdornment";
import { http } from "../../Services/api.services";
import { LoadingButton } from "@mui/lab";
import { showToast } from "../../store/Actions/Action";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";

const FieldLabelCell = ({ title, index }) => (
	<h6 className="text-[#666666] text-sm lg:text-xs font-semibold my-2">
		{title}
	</h6>
);

const _indexes = {
	NIFTY: "nifty",
	BANKNIFTY: "banknifty",
	FINNIFTY: "finnifty",
	MIDCPNIFTY: "midcpnifty",
	SENSEX: "sensex",
	BANKEX: "bankex",
};

const _basketOrderForm = {
	index: "",
	strike: "",
	expiry: "",
	quantity: "",
	price: null,
	transaction_type: "BUY",
	instrument_type: "PE",
};

// BUTTONS STYLES
const BuyButton = styled(Button)(({ theme }) => ({
	color: "#00B386",
	backgroundColor: "#F1FBF6",
	border: "1px solid #00B386",
	"&:hover": {
		backgroundColor: "#F1FBF6",
	},
	minWidth: "24px",
	width: "30px",
	height: "30px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: 10,
	fontWeight: "bold",
	borderRadius: 5,
	padding: "8px 16px",
	textTransform: "capitalize",
	// boxShadow: 'none'
}));

const SellButton = styled(Button)(({ theme }) => ({
	color: "#EB5B3CF7",
	backgroundColor: "#FFF6F6",
	border: "1px solid #EB5B3CF7",
	"&:hover": {
		backgroundColor: "#FFF6F6",
	},
	minWidth: "24px",
	width: "30px",
	height: "30px",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	fontSize: 10,
	fontWeight: "bold",
	borderRadius: 5,
	padding: "8px 16px",
	textTransform: "capitalize",
	// boxShadow: 'none'
}));

const positionsInstruments = {};

const AddOrderForm = ({
	closeForm,
	open,
	isModifying,
	orderModifyStatus,
	executionType,
	onExitOrder,
	pendingIndex,
}) => {
	const dispatch = useDispatch();
	const market = useMarketContext();
	const { currentBasketFormData, modifyFormData, currentPosition, margins } =
		useSelector((state) => state.Baskets);
	const memoizedMarket = React.useMemo(() => market, [market]);
	const allCurrentExpiry = useSelector((state) => state.allCurrentExpiry);
	const { admin } = useSelector((state) => state.admin);
	const user = useSelector((state) => state.user);
	const id = admin ? admin?.id : user?.id;
	const [loading, setLoading] = React.useState(false);
	const [basketOrderForm, setBasketOrderForm] = React.useState({
		..._basketOrderForm,
	});
	const [refresh, setRefresh] = React.useState(false);

	React.useEffect(() => {
		if (modifyFormData) {
			setBasketOrderForm(modifyFormData);
		}
	}, [modifyFormData]);

	// UPDATE THE FORM DATA
	const updateFormData = (field, value) => {
		setBasketOrderForm((prevData) => {
			if (field.includes(".")) {
				const [outerKey, innerKey] = field.split(".");
				return {
					...prevData,
					[outerKey]: {
						...prevData[outerKey],
						[innerKey]: value,
					},
				};
			}
			return {
				...prevData,
				[field]: value,
			};
		});
	};

	// STYLES TO MANAGE FORM TEXTFILEDS AND AUTOCOMPLETES ON VARIOUS SCREENS

	const textfieldStyle = {
		borderRadius: "5px",
		"& .MuiInputBase-root": {
			fontSize: {
				xs: "10px",
				sm: "10px",
				md: "11px",
				lg: "10px",
				xl: "12px",
			},
			maxWidth: {
				xs: "40px", // Small devices
				sm: "40px", // Small devices
				md: "40px", // Medium devices
				lg: "40px", // Large devices
				xl: "60px", // Extra large devices
			},
			minWidth: {
				xs: "40px", // Small devices
				sm: "40px", // Small devices
				md: "40px", // Medium devices
				lg: "40px", // Large devices
				xl: "60px", // Extra large devices
			},
		},
	};

	const autoTextfieldStyle = {
		borderRadius: "5px",
		"& .MuiInputBase-root": {
			fontSize: {
				xs: "10px",
				sm: "10px",
				md: "10px",
				lg: "10px",
				xl: "12px",
			},
			maxWidth: {
				xs: "60px", // Small devices
				sm: "60px", // Small devices
				md: "60px", // Medium devices
				lg: "60px", // Large devices
				xl: "100px", // Extra large devices
			},
			minWidth: {
				xs: "60px", // Small devices
				sm: "60px", // Small devices
				md: "60px", // Medium devices
				lg: "60px", // Large devices
				xl: "100px", // Extra large devices
			},
		},
	};

	// STYLES TO MANAGE FORM TEXTFILEDS AND AUTOCOMPLETES ON VARIOUS SCREENS

	// console.log(executionType)

	// NEW FUNCTION TO EXECUTE SINGLE ORDER ADDITION IN LEG
	const addPositionOrder = async (basketId) => {
		setLoading(true);
		try {
			const type = executionType == 'add' ? 'orders' : executionType == 'modify' ? 'open_orders' : 'pendings'
			console.log(executionType)
			const _payload = { ...basketOrderForm };
			const _order = JSON.parse(currentPosition[type]).find(o => o.tradingsymbol == _payload.tradingsymbol)
			delete _payload.tradingsymbol;
			_payload.user_id = id;
			const orderBody = {
				"exchange": _order.exchange,
				"index": _order.index,
				"instrument_token": _order.instrument_token,
				"price": _payload.price || 0,
				"order_type": parseFloat(_payload.price) > 0 ? "LIMIT" : "MARKET",
				"product": "NRML",
				"quantity": _payload.transaction_type?.toLowerCase() == 'buy' ? Math.abs(parseInt(_payload.quantity)) : -Math.abs(parseInt(_payload.quantity)),
				"tradingsymbol": _order.tradingsymbol,
				"transaction_type": _payload.transaction_type,
				"expiry": _order.expiry,
				"validity": "DAY",
			}
			const body = {
				buy: [],
				sell: [],
				basket_id: currentPosition.id,
				stoploss: _payload?.stoploss,
				user_id: admin?.id,
				type: executionType == 'add' ? 'pentry' : executionType,
				pid: currentPosition?.pid,
			}
			
			if (executionType == 'modify') {
				orderBody["order_id"] = _order.order_id
				orderBody['limit'] = parseFloat(_payload.price)
			}
			if(executionType == 'pmodify') {
				orderBody['limit'] = parseFloat(_payload.price)
			}
			if (_payload.transaction_type?.toLowerCase() == 'buy') {
				body.buy.push(orderBody)
			}
			else {
				if (executionType === 'pmodify') {
					const pendings = JSON.parse(currentPosition['pendings'])
					body.bh_id = _order?.id
					pendings[pendingIndex] = orderBody
					body.sell = pendings
					// console.log(body, executionType, pendingIndex, pendings, currentPosition)
				}
				else body.sell.push(orderBody)
			}
			const response = await http.post(`orders/basket/executeorders`, body)
			setLoading(false);
			if (response?.status == 200) {
				if (executionType == 'modify') {
					dispatch(updateCurrentPosition({ ...currentPosition, open_orders: JSON.stringify(body[_payload.transaction_type?.toLowerCase()]) }))
				}
				else if (executionType == 'pmodify') {
					dispatch(updateCurrentPosition({ ...currentPosition, pendings: JSON.stringify(body.sell) }))
				}
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				closeForm();
			}
		} catch (e) {
			setLoading(false);
			console.log(`ERROR WHILE ADDING ORDER ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	const updateOpenOrder = async () => {
		try {
			// UPDATE OPEN ORDERS - UPDATE PRICE AND QUANTITY OF THE OPEN ORDER AND SET TO REDUX`
			
		}
		catch (e) {
			console.log('ERROR WHILE UPDATING OPEN ORDER', e)
		}
	}


	// FORMER -- ADD SINGLE ORDER IN BASKET POSITIONS
	const _addPositionOrder = async (basketId) => {
		setLoading(true);
		try {
			const _payload = { ...basketOrderForm };
			delete _payload.tradingsymbol;
			_payload.user_id = id;

			const response = await http.post(
				`orders/basket/${basketId}/order`,
				_payload
			);
			setLoading(false);
			if (response?.status == 201) {
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				closeForm();
			}
		} catch (e) {
			setLoading(false);
			console.log(`ERROR WHILE ADDING ORDER ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	// MODIFY OPEN ORDER
	const modifyPositionOrder = async (basketId) => {
		setLoading(true);
		try {
			const _payload = {
				...basketOrderForm,
				price: parseInt(basketOrderForm.price, 10),
				quantity: parseInt(basketOrderForm.quantity, 10),
			};
			_payload.user_id = id;
			_payload.basket_id = basketId;
			_payload.order_type = basketOrderForm?.price ? "LIMIT" : "MARKET";
			const response = await http.post(`orders/basket/order/modify`, _payload);
			console.log(response);
			setLoading(false);
			if (response?.status == 201) {
				const _snackbar = {
					message: response?.data?.message,
					open: true,
					duration: 3000,
					type: "success",
				};
				dispatch(showToast(_snackbar));
				closeForm();
			}
		} catch (e) {
			setLoading(false);
			console.log(`ERROR WHILE MODIFYING ORDER ${e}`);
			const _snackbar = {
				message: e?.response?.data?.error,
				open: true,
				duration: 3000,
				type: "error",
			};
			dispatch(showToast(_snackbar));
		}
	};

	// FETCH MARGINS;
	const getMargins = async () => {
		setRefresh(true);
		try {

			let orders = [];
			let _orderBody
			const _instruments = market[basketOrderForm.index.toLowerCase()][allCurrentExpiry[basketOrderForm.index.toLowerCase()]]?.options[basketOrderForm.instrument_type] || [];

			// Convert to array
			const _filteredinstruments = Object.values(_instruments);

			const filteredStrike = _filteredinstruments?.filter((instrument, idx) => {
				return instrument?.strike === basketOrderForm?.strike;
			});
			_orderBody = {
				exchange: filteredStrike[0]?.exchange,
				tradingsymbol: filteredStrike[0]?.tradingsymbol,
				transaction_type: basketOrderForm?.transaction_type,
				order_type: basketOrderForm?.price ? "LIMIT" : "MARKET",
				quantity: basketOrderForm?.quantity,
				price: basketOrderForm?.price ? basketOrderForm?.price : 0,
			};
			_orderBody.price = parseFloat(_orderBody.price)
			orders.push(_orderBody);

			let _body = { orders };

			const response = await http.post(
				`orders/${id}/basket/order/margins-required`,
				_body
			);
			setRefresh(false);
			if (response?.status == 200) {
				dispatch(updateMargins(response?.data));
			}
		} catch (e) {
			setRefresh(false);
			console.log(`ERROR WHILE FETCHING MARGINS ${e}`);
		}
	};

	return (
		<>
			<div className="w-full bg-[#FFFFFF] rounded px-4 pt-6">
				<div className="flex w-full  items-center flex-wrap justify-between py-6  border-b border-b-[#5367FF1A]">
					{/* <div className="min-w-[120px] lg:min-w-[80px] xl:min-w-[120px] max-w-[120px] lg:max-w-[80px] xl:max-w-[120px]"> */}
					<div className="">
						<FieldLabelCell title=" Index" />
						<TextField
							className="font-bold"
							// label={form?.expiry ? null : "Expiry"}
							name={"expiry"}
							sx={autoTextfieldStyle}
							variant="outlined"
							value={basketOrderForm.index}
							readOnly
							//
							type="text"
							size="small"
						// onChange={(e) => onOrderFormCahnge(e)}
						/>
					</div>
					<div className="">
						<FieldLabelCell title="Expiry" />
						<TextField
							className="font-bold"
							// label={form?.expiry ? null : "Expiry"}
							name={"expiry"}
							sx={autoTextfieldStyle}
							variant="outlined"
							value={basketOrderForm.expiry}
							readOnly
							//
							type="text"
							size="small"
						// onChange={(e) => onOrderFormCahnge(e)}
						/>
					</div>
					<div className="">
						<FieldLabelCell title="Strike" />
						<TextField
							className="font-bold"
							// label={form?.srike ? null : "Strike"}
							name={"strike"}
							variant="outlined"
							size="small"
							type="text"
							readOnly
							sx={autoTextfieldStyle}
							value={basketOrderForm.strike}
						// onChange={(e) => {
						//   onOrderFormCahnge(e);
						// }}
						/>
					</div>
					<div className="">
						<FieldLabelCell title="Qty" />
						<TextField
							className="font-bold"
							//   label={"Qty"}
							name={"quantity"}
							variant="outlined"
							size="small"
							sx={textfieldStyle}
							value={basketOrderForm.quantity}
							type="text"
							onChange={(e) => updateFormData("quantity", e.target.value)}
						/>
					</div>
					<div className="">
						<FieldLabelCell title="lmt" />
						<FormControl size="small">
							<TextField
								className="font-bold"
								name={"price"}
								variant="outlined"
								size="small"
								//
								type="text"
								sx={textfieldStyle}
								value={basketOrderForm.price}
								onChange={(e) => updateFormData("price", e.target.value)}
							/>
						</FormControl>
					</div>
					<div>
						<FieldLabelCell title="B/S" />
						{basketOrderForm?.transaction_type == "BUY" ? (
							<BuyButton
								// onClick={() => {
								//   onOrderFormCahnge("transaction_type", index, "SELL");
								// }}
								size="small"
								variant="contained"
							>
								B
							</BuyButton>
						) : (
							<SellButton
								// onClick={() => {
								//   onOrderFormCahnge("transaction_type", index, "BUY");
								// }}
								size="small"
								variant="contained"
							>
								S
							</SellButton>
						)}
					</div>
					<div>
						<FieldLabelCell title="PE/CE" />
						{basketOrderForm?.instrument_type == "PE" ? (
							<SellButton
								// onClick={() => {
								//   onOrderFormCahnge("instrument_type", index, "CE");
								// }}
								size="small"
								variant="contained"
							>
								PE
							</SellButton>
						) : (
							<BuyButton
								// onClick={() => {
								//   onOrderFormCahnge("instrument_type", index, "PE");
								// }}
								size="small"
								variant="contained"
							>
								CE
							</BuyButton>
						)}
					</div>
				</div>

				<div className="w-full flex gap-3 border-b border-b-[#5367FF1A] py-6">
					<div className="w-1/3 flex gap-2">
						<h6 className="text-[#666666] text-sm lg:text-sm font-semibold my-2">
							SL
						</h6>
						<TextField
							placeholder="Stop Loss"
							size="small"
							variant="outlined"
							sx={{
								borderRadius: "5px",
								fontSize: "10px",
								"& .MuiInputBase-input": {
									textAlign: "center",
								},
								"& .MuiInputBase-root": {
									fontSize: "12px",
								},
							}}
							onChange={(e) => {
								if (!isNaN(e.target.value)) {
									updateFormData("stoploss.stoploss", e.target.value);
								}
							}}
							value={
								basketOrderForm?.stoploss?.stoploss !== undefined
									? basketOrderForm.stoploss.stoploss
									: ''
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<IconButton
											onClick={(e) => {
												e.stopPropagation();

												// Create a copy of the form object
												const _form = { ...basketOrderForm };

												if (_form?.stoploss?.stoploss > 0) {
													// Update the target field in the form object
													const _updateData = {
														..._form,
														stoploss: {
															..._form?.stoploss,
															stoploss: _form.stoploss.stoploss - 1,
														},
													};

													// UPDATE THE FORM FIELDS the updated form state
													setBasketOrderForm(_updateData);
												}
											}}
										>
											<RemoveIcon sx={{ fontSize: "16px" }} />
										</IconButton>
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={(e) => {
												e.stopPropagation();

												// Create a copy of the form object
												const _form = { ...basketOrderForm };

												// Update the stoploss field in the form object
												const _updateData = {
													..._form,
													stoploss: {
														..._form.stoploss,
														stoploss: _form.stoploss.stoploss + 1, // Assuming `stoploss` is a number in the form state
													},
												};

												// UPDATE THE FORM FIELDS the updated form state
												setBasketOrderForm(_updateData);
											}}
										>
											<AddIcon sx={{ fontSize: "16px" }} />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
					<div className="w-1/3 flex gap-2">
						<h6 className="text-[#666666] text-sm lg:text-sm font-semibold my-2">
							TP
						</h6>
						<TextField
							placeholder="Target Price"
							size="small"
							variant="outlined"
							sx={{
								borderRadius: "5px",
								fontSize: "10px",
								"& .MuiInputBase-input": {
									textAlign: "center",
								},
								"& .MuiInputBase-root": {
									fontSize: "12px",
								},
							}}
							onChange={(e) => {
								if (!isNaN(e.target.value)) {
									updateFormData("stoploss.target", e.target.value);
								}
							}}
							value={
								basketOrderForm?.stoploss?.target !== undefined
									? basketOrderForm.stoploss.target
									: ''
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<IconButton
											onClick={(e) => {
												e.stopPropagation();

												// Create a copy of the form object
												const _form = { ...basketOrderForm };

												if (_form?.stoploss?.target > 0) {
													// Update the target field in the form object
													const _updateData = {
														..._form,
														stoploss: {
															..._form.stoploss,
															target: _form.stoploss.target - 1,
														},
													};

													// UPDATE THE FORM FIELDS the updated form state
													setBasketOrderForm(_updateData);
												}
											}}
										>
											<RemoveIcon sx={{ fontSize: "16px" }} />
										</IconButton>
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={(e) => {
												e.stopPropagation();

												// Create a copy of the form object
												const _form = { ...basketOrderForm };

												// Update the stoploss field in the form object
												const _updateData = {
													..._form,
													stoploss: {
														..._form.stoploss,
														target: _form.stoploss.target + 1, // Assuming `stoploss` is a number in the form state
													},
												};

												// UPDATE THE FORM FIELDS the updated form state
												setBasketOrderForm(_updateData);
											}}
										>
											<AddIcon sx={{ fontSize: "16px" }} />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
				</div>
				<div className="w-full flex flex-col items-start justify-end gap-3 py-6">
					<div className="flex  items-center gap-3">
						<h6 className="text-sm text-[#989898]">
							➡️ Margin reqd :{" "}
							<span className="font-bold">
								{margins?.required_margin
									? margins?.required_margin?.toFixed(2)
									: "- -"}
							</span>
						</h6>
						<h6 className="text-sm text-[#989898]">
							💰 Avail. Fund :{" "}
							<span className="font-bold">
								{margins?.available_margin
									? margins?.available_margin?.toFixed(2)
									: "- -"}
							</span>
						</h6>
						<Tooltip title="Reload Margin">
							<IconButton
								className={`${refresh ? "animate-spin" : ""}`}
								onClick={() => {
									getMargins();
								}}
								color="buy"
								aria-label="delete"
							>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</div>
					<div className="w-full flex items-start justify-end gap-3 py-6">
						<Button onClick={() => closeForm()} variant="outlined" autoFocus>
							Cancel
						</Button>
						<LoadingButton
							onClick={() => {
								if (isModifying) {
									addPositionOrder(currentPosition?.id);
								} else {
									if (orderModifyStatus == "exit") {
										onExitOrder(basketOrderForm);
									} else {
										addPositionOrder(currentPosition?.id);
									}
								}
							}}
							variant="contained"
							autoFocus
							loading={loading}
						>
							{orderModifyStatus == "exit" ? "Exit" : "Execute"}
						</LoadingButton>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddOrderForm;
