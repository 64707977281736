import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import reducer from '../store/Reducer/Reducer'
import MarketReducer from '../store/Reducer/MarketReducer'
import TickerReducer from '../store/Reducer/TickerReducer'
import AdminReducer from '../store/Reducer/AdminReducer'
import ExpiryReducer from '../store/Reducer/ExpiryReducer'
import RankingReducer from '../store/Reducer/RankingReducer'
import SLReducer from '../store/Reducer/SLReducer'
import OrderReducer from '../store/Reducer/OrderReducer'
import ToolsReducer from '../store/Reducer/ToolsReducer'
import BasketReducer from '../store/Reducer/BasketReducer'
import UserReducer from './Reducer/UserReducer'
import IndexExchangeReducer from './Reducer/IndexExchangeReducer'

const rootReducer = combineReducers({
    admin: AdminReducer,
    user: UserReducer,
    market: MarketReducer,
    optionHome: reducer,
    tools: ToolsReducer,
    ticks: TickerReducer,
    rankings: RankingReducer,
    allCurrentExpiry: ExpiryReducer,
    SL: SLReducer,
    orders: OrderReducer,
    Baskets: BasketReducer,
    indexExchange: IndexExchangeReducer
})

export const adminStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

