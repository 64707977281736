import InProgress from '../assets/InProgress.webp'

const WorkInProgress = () => {
    return (
        <div className="w-full h-full flex flex-col items-center justify-center">
            <img className='grayscale' src={InProgress} alt="" />
            <br></br>
            <p className='text-3xl font-bold text-slate-500'>Work in progress...</p>
        </div>
    )
}

export default WorkInProgress