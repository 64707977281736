import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ApiService, http } from '../../Services/api.services';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import { updateUser } from '../store/Reducer/UserReducer';
const api = new ApiService()

const AuthBroker = () => {
    const { user } = useSelector((state) => state.user)

    console.log(user);


    const navigate = useNavigate()
    const location = useLocation()
    // const dispatch = useDispatch()
    const [status, setStatus] = React.useState('loading')

    React.useEffect(() => {
        // console.log('jetere - ', location)
        init()
    }, []);

    const init = async () => {
        try {
            const searchParams = new URLSearchParams(window.location.search);
            console.log('searchParams -- ', searchParams);
            const requestToken = searchParams.get('request_token');
            console.log('requestToken -- ', requestToken);
            let _user = localStorage.getItem('before-connect');
            _user = _user ? JSON.parse(_user) : _user
            console.log(_user);
            const user_id = _user?.id || localStorage.getItem('user_id');
            console.log(user);
            const admin = location.pathname?.includes('/admin')
            const response = await api.authBroker(user_id, requestToken, admin)
            await wait(1000)
            const admin_reponse = await api.getUserAuthStatus(user_id)
            console.log(admin_reponse)
            console.log(response)
            if (response?.status == "success" || admin_reponse.active) {
                setStatus(response?.status)
            }
            else {
                setStatus(false)
            }
            await wait(3000)
            if (_user) {
                localStorage.removeItem('before-connect')
                if (admin) {
                    window.open('/admin/options/strikes', "_self")
                }
                // else window.open('/admin/options/users', "_self")
            }
            else
                window.open('/index-options/strikes', "_self")
        }
        catch (e) {
            console.log(`ERROR WHILE LOGIN --- ${e}`)
        }
    }

    const fetchUser = async (userId) => {
        try {
            const response = await http.get(`user/${userId}`)
            if (response.status == 200) {
                // dispatch(updateUser({ type: 'user/updateUser', payload: response.data }))
                // console.log(response.data)
            }
        }
        catch (e) {
            console.log('ERROR WHILE FETCHING USER --- ', e)
        }
    }

    const wait = (ms) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true)
            }, ms);
        })
    }

    const Loading = () => (
        <div className='flex flex-col gap-3 items-center justify-center w-full h-screen'>
            <Box sx={{ display: 'flex' }}>
                <CircularProgress size={40} />
            </Box>
            <h1 className='text-[#373737] text-[20px] lg:text-[28px]'>Authenticating</h1>
        </div>
    )

    const Failed = () => (
        <div className='flex flex-col gap-3 items-center justify-center w-full h-screen'>
            <Box sx={{ display: 'flex' }}>
                <ErrorIcon className='!text-[60px]' color='error' />
            </Box>
            <h1 className='text-[#373737] text-[20px] lg:text-[28px]'>Failed!!</h1>
        </div>
    )

    const Success = () => (
        <div className='flex flex-col gap-3 items-center justify-center w-full h-screen'>
            <Box sx={{ display: 'flex' }}>
                <CheckCircleIcon className='!text-[60px]' color='success' />
            </Box>
            <h1 className='text-[#373737] text-[20px] lg:text-[28px]'>Authenticated!</h1>
        </div>
    )

    return (
        <>
            {!status ? <Failed /> : status === 'success' ? <Success /> : <Loading />}
        </>
    )
}

export default AuthBroker
