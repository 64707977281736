import * as React from 'react';
import Button from '@mui/material/Button';
import ToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const MyToggleButton = styled(ToggleButton)(({ theme }) => ({
    [`&.${toggleButtonClasses.root}`]: { // Use .root for the root class
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: '0 1vh'
    }
}));


const Header = ({ expiry, onIndexChange, currentIndex }) => {

    const updateCurrentIndex = (data) => {
        onIndexChange(data)
    }

    const [alignment, setAlignment] = React.useState('web');

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <>
            <div className='w-full flex flex-col items-center justify-center gap-5 py-5 lg:flex-row lg:justify-between'>
                <div className='flex flex-col gap-5 items-center justify-between px-2 w-full lg:flex-row lg:w-fit lg:gap-16'>
                    <div className='flex items-center justify-between w-full gap-1 lg:gap-4 lg:w-fit flex-wrap'>
                        {/* <Button onClick={() => updateCurrentIndex('nifty')} className='text-[#FFFFFF]  !bg-[#373737] !font-semibold lg:!font-normal !py-1  !text-[11px]' variant="contained">NIFTY</Button> */}
                        <Button
                            onClick={() => updateCurrentIndex('nifty')}
                            className="!font-semibold lg:!font-normal !py-1 !text-[11px]"
                            style={{
                                backgroundColor: currentIndex === 'nifty' ? '#373737' : '#f4f4f4',
                                color: currentIndex === 'nifty' ? '#F4F4F4' : '#373737',
                            }}
                            variant="contained"
                        >
                            NIFTY
                        </Button>
                        <Button
                            onClick={() => updateCurrentIndex('banknifty')}
                            style={{
                                backgroundColor: currentIndex === 'banknifty' ? '#000000' : '#f4f4f4',
                                color: currentIndex === 'banknifty' ? '#F4F4F4' : '#373737',
                            }}
                            className='!font-semibold lg:!font-normal !py-1 !text-[12px]' variant="contained">
                            BANK
                        </Button>
                        <Button
                            onClick={() => updateCurrentIndex('finnifty')}
                            style={{
                                backgroundColor: currentIndex === 'finnifty' ? '#000000' : '#f4f4f4',
                                color: currentIndex === 'finnifty' ? '#F4F4F4' : '#373737',
                            }}
                            className='  !font-semibold lg:!font-normal !py-1 !text-[12px]' variant="contained">
                            FIN
                        </Button>
                        <Button
                            onClick={() => updateCurrentIndex('midcpnifty')}
                            style={{
                                backgroundColor: currentIndex === 'midcpnifty' ? '#000000' : '#f4f4f4',
                                color: currentIndex === 'midcpnifty' ? '#F4F4F4' : '#373737',
                            }}
                            className='  !font-semibold lg:!font-normal !py-1 !text-[12px]' variant="contained">
                            MIDCP
                        </Button>
                        <Button
                            onClick={() => updateCurrentIndex('sensex')}
                            style={{
                                backgroundColor: currentIndex === 'sensex' ? '#000000' : '#f4f4f4',
                                color: currentIndex === 'sensex' ? '#F4F4F4' : '#373737',
                            }}
                            className='  !font-semibold lg:!font-normal !py-1 !text-[12px]' variant="contained">
                            SENSEX
                        </Button>
                        {/* <Button className='!text-[#373737] !font-semibold lg:!font-normal' variant="contained" disabled>SENSEX</Button> */}
                    </div>
                </div>
                <div>
                    <div className='shrink-0'>
                        {/* <h6 className='text-[#373737] font-semibold  text-[16px] m-0 text-center'>19,674.25</h6> */}
                        <h6 className='text-[#817E7E] font-semibold  text-[14px] m-0 text-center'>NIFTY</h6>
                        <p className='text-[#A7A7A7] font-normal  text-[13px] m-0 text-center'>28-SEP-2023</p>
                        <div className='flex items-center  gap-2'>
                            <h6 className='text-[#373737] font-semibold m-0 text-[14px] text-center'><span className='text-[#666666] text-[12px] font-normal'>Price</span>&nbsp; 19,674.25 </h6>
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.20)' }} className='h-[20px] w-[1px] rounded-sm'>

                            </div>
                            <h6 className='text-[#00A880C7] font-semibold m-0 text-[14px] text-center'><span className='text-[#666666] text-[12px] font-normal'>Eq</span>&nbsp; 18,674.25 <span>(20%)</span></h6>
                        </div>
                    </div>
                </div>
                {expiry && (
                    <div className='flex items-center justify-between px-2 w-full py-3 lg:w-fit'>
                        <div className='block lg:hidden'>
                            <ToggleButtonGroup
                                size='small'
                                // color="primary"
                                className='!text-red-500'
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                                sx={{}}
                            >
                                <MyToggleButton value="web">LTP</MyToggleButton>
                                <ToggleButton value="android">OI</ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                        <div className='flex items-center gap-2 h-fit'>
                            {/* <div onClick={toggleDrawer(true)} className='w-fit h-full border border-[#DFDFDF] p-1 rounded-sm lg:hidden'>
                                <ShowChartIcon className='text-[#373737]' />
                            </div> */}
                            <TextField id="outlined-basic" InputLabelProps={{ shrink: true, }} size='small' type='date' label="Expiry" variant="outlined" />
                        </div>

                    </div>
                )}

            </div>
        </>
    )
}


export default Header